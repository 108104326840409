/**
 * Copyright 2022 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Dropdown } from "openstack-uicore-foundation/lib/components";
import { clearSelectionPlan } from "../actions/base-actions";
import {canAccessToSelectionPlan, isAdmin, nowBetween} from "../utils/methods";
import history from "../history";
import styles from "../styles/selection-plan-selection-page.module.scss";
import {adminGroups} from "../model/groups";

const SelectionPlanSelectionPage = ({ summit, member, loading, match, clearSelectionPlan, loggedTrackChair }) => {
  const selPlans = summit?.selection_plans || [];
  const availablePlans = selPlans.filter((sp) => {
    return sp.is_enabled && (nowBetween(sp.selection_begin_date, sp.selection_end_date) || isAdmin(member));
  });

  useEffect(() => {
    clearSelectionPlan();
  }, []);

  const onChange = (ev) => {
    const { value } = ev.target;
    history.push(`${match.url}/${value}`);
  };

  if (loading) return null;

  if (!selPlans || selPlans.length === 0) {
    return (
      <div className={`container ${styles.wrapper}`}>
        <p>There isn't available selection plans</p>
      </div>
    );
  }

  const planOpts = selPlans.filter(sp=> canAccessToSelectionPlan(sp, member, loggedTrackChair)).map((sp) => {
    const isOpen = sp.is_enabled && nowBetween(sp.selection_begin_date, sp.selection_end_date);
    const label = `${sp.name}${isOpen ? '' : ' (closed)'}`;
    return ({ ...sp, value: sp.id, label });
  });

  return (
    <div className={`container ${styles.wrapper}`}>
      <h1>Please choose a selection plan</h1>
      <div className={styles.selectWrapper}>
        <Dropdown options={planOpts} onChange={onChange} className={styles.dropdown} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ baseState, loggedUserState }) => ({
  member: loggedUserState.member,
  loggedTrackChair: baseState.loggedTrackChair,
  loading: baseState.loading,
  summit: baseState.summit,
});

export default connect(mapStateToProps, { clearSelectionPlan })(SelectionPlanSelectionPage);
