import React from 'react';
import Dropdown, {DropdownItem} from '../Dropdown';

const PresentationFilter = ({
	activeFilter,
	onSelect,
	actionTypes
}) => (
	<Dropdown
		selectedText={<i className='fa fa-filter' />}
		className="filter-dropdown"
		id="presentation-filter"
		buttonClassName={!activeFilter || activeFilter === 'all' ? '' : 'btn-primary'}
	>
		<DropdownItem eventKey='all' onSelect={onSelect} active={!activeFilter || activeFilter === 'all'}>
			<i className='fa fa-list' /> All
		</DropdownItem>
		<DropdownItem divider />
		<DropdownItem eventKey='team_selected' onSelect={onSelect} active={activeFilter === 'team_selected'}>
			<i className='fa fa-group' /> Team selections
		</DropdownItem>
		<DropdownItem divider />
		<DropdownItem onSelect={onSelect} active={activeFilter === 'untouched'} eventKey='untouched'><i className='fa fa-exclamation-circle' /> Needs vote</DropdownItem>
		<DropdownItem onSelect={onSelect} active={activeFilter === 'moved'} eventKey='moved'><i className='fa fa-arrow-right' /> Just moved here</DropdownItem>
		<DropdownItem divider />
		<DropdownItem onSelect={onSelect} active={activeFilter === 'voted'} eventKey='voted'><i className='fa fa-check' /> Voted on (all)</DropdownItem>
		<DropdownItem onSelect={onSelect} active={activeFilter === 'selected'} eventKey='selected'><i className='fa fa-star success' /> Vote: Selected</DropdownItem>
		<DropdownItem onSelect={onSelect} active={activeFilter === 'maybe'} eventKey='maybe'><i className='fa fa-thumbs-up warning' /> Vote: Interested</DropdownItem>
		<DropdownItem onSelect={onSelect} active={activeFilter === 'pass'} eventKey='pass'><i className='fa fa-thumbs-down danger' /> Vote: No thanks</DropdownItem>
		<DropdownItem divider />
		{actionTypes.map(at => {
			const key = `action_${at.id}`;
			return (
				<DropdownItem key={key} onSelect={onSelect} active={activeFilter === key} eventKey={key}>{at.label}</DropdownItem>
			);
		})}
	</Dropdown>
);

export default PresentationFilter;