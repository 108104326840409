import React from 'react';
import styles from './index.module.scss';

export default () => (
	<div className={`spinner ${styles.waveLoader}`}>
	  <div className={styles.rect1} />
	  <div className={styles.rect2} />
	  <div className={styles.rect3} />
	  <div className={styles.rect4} />
	  <div className={styles.rect5} />
	</div>		
);