/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React, {Component} from 'react';
import {connect} from "react-redux";
import {initLogOut} from "openstack-uicore-foundation/lib/security/methods";

import styles from '../styles/unauthorized-page.module.scss';

class UnauthorizedPage extends Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <h1>Restricted Access</h1>
                <h4>Your user does not have permission to access this application. You may need to logout and log back in if you were just granted permission.</h4>

                <div className={styles.logout}>
                    <button className="btn btn-primary btn-lg" onClick={() => this.props.initLogOut()}>
                        Log out
                    </button>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    initLogOut,
})(UnauthorizedPage)


