/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler
} from "openstack-uicore-foundation/lib/utils/actions";
import {LISTS_CREATED} from "./presentation-actions";
import {getAccessTokenSafely} from "../utils/methods";

export const REQUEST_TRACK_CHAIRS           = 'REQUEST_TRACK_CHAIRS';
export const RECEIVE_TRACK_CHAIRS           = 'RECEIVE_TRACK_CHAIRS';
export const REQUEST_LISTS                  = 'REQUEST_LISTS';
export const RECEIVE_LISTS                  = 'RECEIVE_LISTS';
export const REMOVE_PRESENTATION            = 'REMOVE_PRESENTATION';
export const REORGANISE_SELECTIONS          = 'REORGANISE_SELECTIONS';
export const SAVING_LIST                    = 'SAVING_LIST';
export const LIST_SAVED                     = 'LIST_SAVED';
export const REQUEST_TEAM_LIST              = 'REQUEST_TEAM_LIST';
export const RECEIVE_TEAM_LIST              = 'RECEIVE_TEAM_LIST';
export const TEAM_LIST_CREATED              = 'TEAM_LIST_CREATED';


export const getTrackChairs = (trackSlug) => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit, allTracks } = baseState;
    const accessToken = await getAccessTokenSafely();
    const track = allTracks.find(c => c.slug === trackSlug);

    if (!track) return;

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
        expand: 'member, categories',
        per_page: 50,
        'filter[]': `track_id==${track.id}`
    };

    return getRequest(
        createAction(REQUEST_TRACK_CHAIRS),
        createAction(RECEIVE_TRACK_CHAIRS),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/track-chairs`,
        authErrorHandler,
        {track}
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const getSelectionLists = (trackId, memberId) => async (dispatch, getState) => {
    const { baseState, selectionsState } = getState();
    const { summit, selectionPlan } = baseState;
    const accessToken = await getAccessTokenSafely();
    const { trackChairs } = selectionsState;
    const trackChair = trackChairs.find(tc => `${tc.member.id}` === memberId);

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
        expand: 'interested_presentations, interested_presentations.presentation, selected_presentations, selected_presentations.presentation'
    };

    return getRequest(
        createAction(REQUEST_LISTS),
        createAction(RECEIVE_LISTS),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${trackId}/selection-lists/individual/owner/${memberId}`,
        authErrorHandler,
        {trackChair}
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const removePresentation = (listType, presentationId) => async (dispatch, getState) => {
    const { baseState, loggedUserState, selectionsState } = getState();
    const { summit, selectionPlan } = baseState;
    const accessToken = await getAccessTokenSafely();
    const {track} = selectionsState;

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
    };

    return deleteRequest(
        null,
        createAction(REMOVE_PRESENTATION)({listType, presentationId}),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${track.id}/selection-lists/individual/presentation-selections/${listType}/presentations/${presentationId}`,
        authErrorHandler,
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const reorderSelections = (listId, type, selections) => (dispatch) => {
    dispatch(createAction(REORGANISE_SELECTIONS)({listId, type, selections}));
};

export const saveLists = () => (dispatch, getState) => {
    const { selectionsState } = getState();
    const {individualList, teamList} = selectionsState;

    if (individualList.isSelectedDirty) {
        const presentationIds = individualList.selected_presentations.map(s => s.presentation.id);
        saveList(individualList.id, 'selected', presentationIds)(dispatch, getState)
    }

    if (individualList.isInterestedDirty) {
        const presentationIds = individualList.interested_presentations.map(s => s.presentation.id);
        saveList(individualList.id, 'maybe', presentationIds)(dispatch, getState)
    }

    if (teamList.isDirty) {
        const presentationIds = teamList.selected_presentations.map(s => s.presentation.id);
        saveList(teamList.id, 'selected', presentationIds, teamList.hash)(dispatch, getState)
    }
};

export const saveList = (listId, collection, presentations, hash = '') => async (dispatch, getState) => {
    const { baseState, selectionsState } = getState();
    const { summit, selectionPlan } = baseState;
    const { track } = selectionsState;
    const accessToken = await getAccessTokenSafely();

    const params = {
        access_token : accessToken,
    };

    dispatch(startLoading());

    putRequest(
        createAction(SAVING_LIST)({listId, collection}),
        createAction(LIST_SAVED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${track.id}/selection-lists/${listId}/reorder`,
        {hash: hash, collection, presentations},
        authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const createLists = () => async (dispatch, getState) => {
    const { baseState, presentationsState } = getState();
    const { summit, selectionPlan } = baseState;
    const { track } = presentationsState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
    };

    postRequest(
        null,
        createAction(LISTS_CREATED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${track.id}/selection-lists/individual/owner/me`,
        {},
        authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const getTeamList = (trackId) => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit, selectionPlan } = baseState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
        expand: 'selected_presentations, selected_presentations.presentation'
    };

    getRequest(
        createAction(REQUEST_TEAM_LIST),
        createAction(RECEIVE_TEAM_LIST),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${trackId}/selection-lists/team`,
        authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const createTeamList = () => async (dispatch, getState) => {
    const { baseState, presentationsState } = getState();
    const { summit, selectionPlan } = baseState;
    const { track } = presentationsState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
    };

    postRequest(
        null,
        createAction(TEAM_LIST_CREATED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${track.id}/selection-lists/team`,
        {},
        authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};