import React from 'react';
import PropTypes from 'prop-types';
import {DropdownButton, MenuItem} from "react-bootstrap";

export const DropdownItem = ({divider, eventKey, children, onSelect, active}) => {
	return divider ?
		(
			<MenuItem divider />
		):(
			<MenuItem onSelect={onSelect} eventKey={eventKey} active={active}>
				{children}
			</MenuItem>
		);
};

DropdownItem.propTypes = {
	divider: PropTypes.bool,
	onItemClick: PropTypes.func,
	eventKey: PropTypes.any
};


class Dropdown extends React.Component {

	constructor (props) {
		super(props);
	}


	componentDidMount () {
		document.addEventListener('click', this.closeOnOuterClick);
	}


	componentWillUnmount () {
		document.removeEventListener('click', this.closeOnOuterClick);
	}


	closeOnOuterClick = e => {
		this.setState({opened: false});
	};


	render () {
		const { children, selectedText, className, buttonClassName = "", id } = this.props;

		return (
		    <div className={`btn-group ${className}`}>
				<DropdownButton title={selectedText} id={id} className={buttonClassName}>
					{children}
				</DropdownButton>
		    </div>
	   	);

	}
}

Dropdown.propTypes = {
	id: PropTypes.string.isRequired,
	onItemSelected: PropTypes.func,
	selectedText: PropTypes.any,
	activeKey: PropTypes.any,
};

export default Dropdown;