import React from 'react';
import ScheduleTeamCard from '../ScheduleTeamCard';
import styles from "./index.module.scss";
import StaticSelectionList from "../../SelectionList/Static";


const SemiStaticTeamList = ({summit, track, selections, locked, ...props}) => {
  
  if (!selections.length > 0) return <div>List is empty</div>;
  
  const sortedSelections = selections.sort((a, b) => a.order - b.order);
  const altThreshold = track.session_count;
  
  if (locked) {
    return (
      <StaticSelectionList selections={sortedSelections} track={track} summit={summit} />
    )
  }
  
  return (
    <div className={styles.wrapper}>
      {sortedSelections.map((s, i) => (
        <ScheduleTeamCard
          id={s.presentation.id}
          key={`sortable-card-${s.presentation.id}`}
          isAlternate={i >= altThreshold}
          rank={s.order}
          presentation={s.presentation}
          summit={summit}
          track={track}
          showRank
          onReset={props.onReset}
        />
      ))}
    </div>
  );
}

export default SemiStaticTeamList;