/**
 * Copyright 2022 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React, {useEffect, useMemo} from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { getSelectionPlan } from "../actions/base-actions";
import BrowsePage from "../pages/browse-page";
import SelectionsPage from "../pages/selections-page";
import SchedulePage from "../pages/schedule-page";
import ChangeRequestsPage from "../pages/change-requests-page";
import {canAccessToSelectionPlan} from "../utils/methods";

const SelectionPlanLayout = ({ match, location, member, summit, selectionPlan, loggedTrackChair, getSelectionPlan }) => {
  const selectionPlanIdParam = useMemo(() => parseInt(match?.params?.selection_plan_id), [match?.params?.selection_plan_id]);

  useEffect(() => {
    if (summit?.id && selectionPlan?.id !== selectionPlanIdParam) {
      getSelectionPlan(summit.id, selectionPlanIdParam);
    }
  }, [summit?.id, selectionPlan?.id, selectionPlanIdParam]);

  if (!member || !summit || selectionPlan?.id !== selectionPlanIdParam) return null;

  if (!canAccessToSelectionPlan(selectionPlan, member, loggedTrackChair)) {
    return <Redirect to={`/app/${summit.slug}`}/>;
  }

  if (location.pathname === `${match.url}/change-requests` && selectionPlan?.allow_track_change_requests === false) {
    return <Redirect to={`/app/${summit.slug}`}/>;
  }

  return (
    <div className="primary-layout container-fluid">
      <main id="page-wrap">
        <Switch>
          <Route strict exact path={`${match.url}/browse`} component={BrowsePage} />
          <Route path={`${match.url}/browse/:track_slug?/:presentation_id?`} component={BrowsePage} />
          <Route path={`${match.url}/selections/:track_slug?/:member_id?`} component={SelectionsPage} />
          <Route path={`${match.url}/proposed-schedule`} component={SchedulePage} />
          <Route path={`${match.url}/change-requests`} component={ChangeRequestsPage} />
          <Route render={props => (<Redirect to={`${match.url}/browse`}/>)}/>
        </Switch>
      </main>
    </div>
  );
};

const mapStateToProps = ({ loggedUserState, baseState }) => ({
  member: loggedUserState.member,
  selectionPlan: baseState.selectionPlan,
  summit: baseState.summit,
  loggedTrackChair: baseState.loggedTrackChair,
});

export default connect(mapStateToProps, {
  getSelectionPlan,
})(SelectionPlanLayout);
