import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import styles from './index.module.scss';

/**
 * @param children
 * @param email
 * @param presentationId
 * @param onPostNote
 * @param className
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const EmailLink = ({ children, email, presentationId, onPostNote, className, title}) => {

	const note = useRef('');
	const [show, setShow] = useState(false);

	const onEmailClick = (ev) => {
		ev.preventDefault();
		setShow(true);
	};

	const onSubmit = () => {
		const noteValue = note.current.value;

		if (noteValue) {
			setShow(false);
			onPostNote(presentationId, noteValue, false);
			window.open(`mailto:${email}`, '_blank');
		}
	};

	return (
		<span className={`${styles.wrapper} ${className}`}>
			<a onClick={onEmailClick} className={styles.link} title={title}>
				{children}
			</a>
			<Modal show={show} onHide={() => setShow(false)} dialogClassName={styles.modalWrapper}>
				<Modal.Header closeButton>
					<Modal.Title>What is this email about?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Please add an activity note with a brief description of what the email involves.</p>
					<textarea ref={note} />
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-default" onClick={() => onSubmit()}>
						Submit
					</button>
				</Modal.Footer>
			</Modal>
		</span>
	);
};

EmailLink.propTypes = {
	email: PropTypes.string.isRequired,
	presentationId: PropTypes.number.isRequired,
	onPostNote: PropTypes.func.isRequired,
	className: PropTypes.string,
	title: PropTypes.string,
};

EmailLink.defaultProps = {
	className: '',
	title : ''
};

export default EmailLink;