import React from 'react';
import Card from '../Card';
import styles from "./index.module.scss";

const StaticSelectionList = ({selections, track, summit, showRank}) => {

    const altThreshold = track.session_count;

    return (
        <div className={`${styles.wrapper} static`}>
            {selections.map((s,i) => (
                <div key={`static-card-${s.presentation.id}`} className={'selection-container' + (i >= altThreshold ? ' alternate' : '')}>
                    <Card
                        presentation={s.presentation}
                        rank={s.order}
                        isAlternate={i >= altThreshold}
                        showRank={showRank}
                        sortable={false}
                        summit={summit}
                        track={track}
                    />
                </div>
            ))}
        </div>
    );
};

export default StaticSelectionList;