import React from 'react';
import {Navbar, Nav, NavItem} from "react-bootstrap";
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

export default ({ loading, summit, selectionPlan, selectionOpen, initLogOut, isLoggedUser, isAuthorized, isAdmin }) => {
	const brand = [<Link key="root" to='/app'>{window.APP_NAME}</Link>];
	const summitSlug = summit ? summit.slug : '';
	const pre = `/app/${summitSlug}/${selectionPlan?.id}`;
	const showNav = selectionOpen && isLoggedUser && isAuthorized && summit && selectionPlan;
	const showNavForAdmin = isLoggedUser && isAdmin && summit && selectionPlan;
	const showProposedSchedule = (showNav || showNavForAdmin) && selectionPlan.allow_proposed_schedules;
	const changeRequestsAllowed = selectionPlan?.allow_track_change_requests !== false

	let selectionPlanName = '';

	if (selectionOpen) {
		selectionPlanName = selectionPlan ? selectionPlan.name : '';
	} else {
		selectionPlanName = selectionPlan ? <span className={styles.closed}>{selectionPlan.name} (Selection Closed)</span> : '';
	}

	if (summit) {
		brand.push(<Link key="summit" to={`/app/${summit.slug}`}>{` - ${summit.name}`}</Link>);
		if (!loading && selectionPlanName) {
			brand.push(<span key="selplan"> - {selectionPlanName}</span>);
		}
	}

	const onLogOut = ev => {
		ev.preventDefault();
		initLogOut();
	};

	const getActiveKey = () => {
		const tabPath = window.location.pathname.replace(`${pre}/`, '');

		if (tabPath.startsWith('browse')) {
			return 'browse';
		} else if (tabPath.startsWith('selections')) {
			return 'selections';
		} else if (tabPath.startsWith('change-requests')) {
			return 'change-requests';
		} else if (tabPath.startsWith('proposed-schedule')) {
			return 'proposed-schedule';
		}

		return null;
	};

	return (
		<Navbar fluid className={styles.wrapper}>
			<Navbar.Header>
				<Navbar.Brand>
					{brand}
				</Navbar.Brand>
				<Navbar.Toggle />
			</Navbar.Header>

			<Navbar.Collapse>
				<Nav activeKey={getActiveKey()} pullRight>
					{/*DO NOT GROUP ITEMS - activeKey gets lost*/}
					<NavItem eventKey="browse" href={`${pre}/browse`}>
						<i className="fa fa-list"/> Browse
					</NavItem>
					{(showNav || showNavForAdmin) &&
					<NavItem eventKey="selections" href={`${pre}/selections`}>
						<i className="fa fa-check-square"/> Selections
					</NavItem>
					}
					{showProposedSchedule &&
						<NavItem eventKey="proposed-schedule" href={`${pre}/proposed-schedule`}>
							<i className="fa fa-calendar"/> Proposed Schedule
						</NavItem>
					}
					{(showNav || showNavForAdmin) && changeRequestsAllowed &&
					<NavItem eventKey="change-requests" href={`${pre}/change-requests`}>
						<i className="fa fa-edit"/> Change Requests
					</NavItem>
					}
					{isLoggedUser &&
					<NavItem href="" onClick={onLogOut}>
						<i className="fa fa-sign-out"/> Log Out
					</NavItem>
					}
				</Nav>
			</Navbar.Collapse>
		</Navbar>

	);
};