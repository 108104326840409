/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React from 'react'
import {Switch, Route, Router, Redirect} from 'react-router-dom'
import PrimaryLayout from "./layouts/primary-layout"
import AuthorizedRoute from './routes/authorized-route'
import AuthorizationCallbackRoute from "./routes/authorization-callback-route"
import LogOutCallbackRoute from './routes/logout-callback-route'
import {connect} from 'react-redux'
import {AjaxLoader} from "openstack-uicore-foundation/lib/components";
import { resetLoading } from "openstack-uicore-foundation/lib/utils/actions";
import { doLogout, onUserAuth, getUserInfo} from 'openstack-uicore-foundation/lib/security/actions';
import { initLogOut, getIdToken} from 'openstack-uicore-foundation/lib/security/methods';
import history from './history';
import CustomErrorPage from "./pages/custom-error-page";
import NoMatchPage from "./pages/no-match-page";
import LoginPage from "./pages/login-page";
import UnauthorizedPage from './pages/unauthorized-page';
import IdTokenVerifier from 'idtoken-verifier';
import Header from './components/Header'
import {adminGroups, authorizedGroups} from './model/groups';
import T from 'i18n-react';

import './styles/general.scss';

// here is set by default user lang as en
let language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

// language would be something like es-ES or es_ES
// However we store our files with format es.json or en.json
// therefore retrieve only the first 2 digits

if (language.length > 2) {
    language = language.split("-")[0];
    language = language.split("_")[0];
}

// DISABLED language - ONLY ENGLISH

T.setTexts(require(`./i18n/en.json`));

// move all env var to global scope so ui core has access to this

window.IDP_BASE_URL             = process.env['IDP_BASE_URL'];
window.API_BASE_URL             = process.env['API_BASE_URL'];
window.MARKETING_API_BASE_URL   = process.env['MARKETING_API_BASE_URL'];
window.OAUTH2_CLIENT_ID         = process.env['OAUTH2_CLIENT_ID'];
window.SCOPES                   = process.env['SCOPES'];
window.APP_NAME                 = process.env['APP_NAME'];
window.OAUTH2_FLOW              = process.env['OAUTH2_FLOW'] || "token id_token";


class App extends React.PureComponent {

    constructor(props) {
        super(props);
        props.resetLoading();
    }

    render() {
        const {isLoggedUser, onUserAuth, doLogout, getUserInfo, selectionPlan, selectionOpen, backUrl, summit, loading, member, loggedTrackChair} = this.props;
        const idToken = getIdToken();
        const authGroups = member ? member.groups.filter(g => authorizedGroups.includes(g.code)) : [];
        const isAuthorized = authGroups.length > 0 || loggedTrackChair;
        const isAdmin = member && !!member?.groups?.some(g => adminGroups.includes(g.code));

        // get user pic from idtoken claims (IDP)
        let profile_pic = '';

        if(idToken){
            let verifier = new IdTokenVerifier({
                issuer:   window.IDP_BASE_URL,
                audience: window.OAUTH2_CLIENT_ID
            });
            let jwt = verifier.decode(idToken);
            profile_pic = jwt.payload.picture;
        }

        return (
            <Router history={history}>
                <div className="main-wrapper" style={{backgroundColor: "#f3f3f4"}}>
                    <AjaxLoader show={loading} size={120}/>
                    <Header
                        loading={loading}
                        summit={summit}
                        selectionPlan={selectionPlan}
                        selectionOpen={selectionOpen}
                        initLogOut={initLogOut}
                        picture={profile_pic}
                        isLoggedUser={isLoggedUser}
                        isAuthorized={isAuthorized}
                        isAdmin={isAdmin}
                    />
                    <Switch>
                        <AuthorizedRoute path="/app" loggedUser={member} isAuthorized={isAuthorized} backUrl={backUrl} component={PrimaryLayout} />
                        <LogOutCallbackRoute path='/auth/logout' doLogout={doLogout} />
                        <AuthorizationCallbackRoute onUserAuth={onUserAuth} path='/auth/callback' getUserInfo={getUserInfo} />
                        <Route exact strict path="/error" component={CustomErrorPage} />
                        <Route exact strict path="/404" component={NoMatchPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/unauthorized" component={UnauthorizedPage} />
                        <Route render={props => (<Redirect to="/app"/>)}/>
                    </Switch>

                </div>
            </Router>
        );
    }
}

const mapStateToProps = ({loggedUserState, baseState}) => ({
    isLoggedUser: loggedUserState.isLoggedUser,
    member: loggedUserState.member,
    ...baseState
});

export default connect(mapStateToProps, {
    onUserAuth,
    doLogout,
    getUserInfo,
    resetLoading
})(App)
