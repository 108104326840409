/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React, {Component} from 'react'
import { Route, Redirect } from 'react-router-dom'

class AuthorizedRoute extends Component {

    render() {
        const { component: Component, loggedUser, ...rest } = this.props;

        return (
            <Route {...rest} render={props => {
                const { location } = this.props;
                let backUrl = location.pathname;

                if (location.search) backUrl += location.search;
                if (location.hash) backUrl += location.hash;
                if (!loggedUser) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                search: `?BackUrl=${encodeURIComponent(backUrl)}`,
                                state: {from: location}
                            }}
                        />
                    );
                }


                return <Component {...props} />;
            }}
            />
        )
    }
}

export default AuthorizedRoute;


