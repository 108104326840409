/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import {
    RECEIVE_PRESENTATION,
    REQUEST_PRESENTATION,
    RECEIVE_PRESENTATIONS,
    REQUEST_PRESENTATIONS,
    SET_PRESENTATIONS_LOADED,
    SET_PRESENTATION,
    PRESENTATION_VIEWED,
    MARK_SELECTION,
    SELECTION_CLEARED,
    SCORE_CLEARED,
    CATEGORY_CHANGE_REQUESTED,
    COMMENT_ADDED,
    FLAG_CHANGED,
    CLEAR_PRESENTATION,
    CAST_PRESENTATION_SCORE,
    RECEIVE_PRESENTATION_AVG_SCORE,
    DURATION_CHANGED
} from "../actions/presentation-actions";

import {LOGOUT_USER} from "openstack-uicore-foundation/lib/security/actions";
import {RECEIVE_AVAILABLE_SUMMITS, RECEIVE_SUMMIT} from "../actions/base-actions";

import {CHANGE_REQUEST_RESOLVED} from "../actions/change-requests-actions";
import {LIST_SAVED} from "../actions/selection-actions";


const DEFAULT_STATE = {
    presentations: [],
    presentationsLoaded: false,
    search: '',
    total: 0,
    page: 1,
    filter: {
        trackSlug: 0,
        status: '',
        search: '',
        tag: ''
    },
    track: null,
    types: [],
    tags: [],
    presentation: null,
    index: 0,
    loadingPresentation: false
};

const presentationsReducer = (state = DEFAULT_STATE, action) => {
    const { type, payload } = action;

    switch(type){
        case RECEIVE_AVAILABLE_SUMMITS:
        case RECEIVE_SUMMIT:
        case LOGOUT_USER:
            return DEFAULT_STATE;
        case SET_PRESENTATIONS_LOADED: {
            return {
                ...state,
                presentationsLoaded: true,
            };
        }
        case REQUEST_PRESENTATIONS: {
            const {page, track, status, search, tag, resetList} = payload;
            track.limit = track.alternate_count + track.session_count;

            return {
                ...state,
                page: resetList ? 1 : page,
                track,
                filter: {trackSlug: track.slug, status, search, tag},
                presentations: resetList ? [] : state.presentations,
                presentationsLoaded: false,
                presentation: null,
                loadingPresentation:false,
            };
        }
        case RECEIVE_PRESENTATIONS: {
            const {data, total, current_page, per_page} = payload.response;
            const {presentations: storedPresentations} = state;
            const storedPresIds = storedPresentations.map(p => p.id);
            let allTags = [];

            const presentations = data.filter(p => !storedPresIds.includes(p.id)).map(p => {
                return processPresentation(p, allTags);
            });

            return {
                ...state,
                total,
                perPage: per_page,
                page: current_page,
                presentations: [...state.presentations, ...presentations],
                presentationsLoaded: true,
                tags: allTags
            };
        }
        case REQUEST_PRESENTATION: {
            return {...state, loadingPresentation: true};
        }
        case RECEIVE_PRESENTATION: {
            const pres = payload.response;
            const {tags, presentations} = state;
            const newPresentations = [...presentations];

            const presentation = processPresentation(pres, tags);
            const presIdx = presentations.findIndex(p => p.id === presentation.id);
            let newIndex = presIdx;

            if (presIdx < 0) {
                newPresentations.push(presentation);
                newIndex = newPresentations.length;
            }

            return {
                ...state,
                presentation,
                presentations: newPresentations,
                index: newIndex,
                loadingPresentation: false
            };
        }
        case SET_PRESENTATION: {
            const {presentation, index} = payload;
            return {...state, presentation, index};
        }
        case PRESENTATION_VIEWED: {
            const updatedPres = {
                ...state.presentation,
                viewed: true
            };
            const updatedPresentations = updatePresentations(state.presentations, updatedPres);

            return {...state, presentations: updatedPresentations, presentation: updatedPres};
        }
        case MARK_SELECTION: {
            const {selection} = payload;

            let updatedPres = updatePresentationStatus(state.presentation, selection);
            let updatedPresentations = updatePresentations(state.presentations, updatedPres);
            updatedPres = updateSelectionsRemaining(updatedPres, state.presentation.selection_status, selection);
            updatedPresentations = updateSelectionsRemainingForAll(updatedPresentations, state.presentation.selection_status, selection);

            return {...state, presentations: updatedPresentations, presentation: updatedPres};
        }
        case SELECTION_CLEARED: {
            let updatedPres = updatePresentationStatus(state.presentation, '');
            let updatedPresentations = updatePresentations(state.presentations, updatedPres);
            updatedPres = updateSelectionsRemaining(updatedPres, state.presentation.selection_status, '');
            updatedPresentations = updateSelectionsRemainingForAll(updatedPresentations, state.presentation.selection_status, '');

            return {...state, presentations: updatedPresentations, presentation: updatedPres};
        }
        case SCORE_CLEARED: {
            const {scoreId} = payload;
            const currentPres = state.presentation;
            const updatedScores = currentPres.track_chair_scores.filter(s => s.id !== scoreId);
            const updatedPres = {...currentPres, track_chair_scores: [...updatedScores]};

            return {...state, presentations: updatePresentations(state.presentations, updatedPres), presentation: updatedPres};
        }
        case LIST_SAVED: {
            // clear collections so it reloads, just in case the change was to the loaded list.
            return {...state, presentationsLoaded: false, presentation: null, presentations: []}
        }
        case CATEGORY_CHANGE_REQUESTED: {
            const changeRequest = payload.response;
            const currentPres = state.presentation;

            const updatedPres = {
                ...currentPres,
                category_changes_requests: [...currentPres.category_changes_requests, changeRequest],
            };

            const updatedPresentations = updatePresentations(state.presentations, updatedPres);

            return {...state, presentations: updatedPresentations, presentation: updatedPres};
        }
        case CHANGE_REQUEST_RESOLVED: {
            const changeRequest = payload.response;
            const currentPres = state.presentation;

            if (changeRequest.status === 'Approved') {
                const updatedPres = state.presentations.filter(p => p.id !== changeRequest.presentation.id);
                return {...state, presentations: updatedPres, presentation: null}
            } else {
                const updatedPres = {
                    ...currentPres,
                    category_changes_requests: [...currentPres.category_changes_requests.filter(cr => cr.id !== changeRequest.id)],
                };

                const updatedPresentations = updatePresentations(state.presentations, updatedPres);

                return {...state, presentations: updatedPresentations, presentation: updatedPres};
            }
        }
        case COMMENT_ADDED: {
            const currentPres = state.presentation;
            const comment = payload.response;
            const updatedPres = {
                ...currentPres,
                comments: [...currentPres.comments, comment],
            };

            const updatedPresentations = updatePresentations(state.presentations, updatedPres);

            return {...state, presentations: updatedPresentations, presentation: updatedPres};
        }
        case FLAG_CHANGED: {
            const currentPres = state.presentation;
            const {type_id, is_completed} = payload.response;

            let updatedActions = currentPres.actions;
            if (is_completed) {
                if (!updatedActions.some(a => a.type_id === type_id)) {
                    updatedActions = [...updatedActions, payload.response]
                } else {
                    updatedActions = updatedActions.map(a => a.type_id === type_id ? ({...a, is_completed}) : a);
                }
            } else {
                updatedActions = updatedActions.filter(a => a.type_id !== type_id);
            }
            
            const updatedPres = {
                ...currentPres,
                actions: updatedActions,
            };

            const updatedPresentations = updatePresentations(state.presentations, updatedPres);

            return {...state, presentations: updatedPresentations, presentation: updatedPres};
        }
        case CLEAR_PRESENTATION: {
            const {presentation} = state;
            return {...state,
                presentations: state.presentations.filter(((item, index) => item.id !== presentation.id)),
                presentation: null
            };
        }
        case CAST_PRESENTATION_SCORE:{
            const score = payload.response;
            const currentPres = state.presentation;
            const updatedPres = {
                ...currentPres,
                track_chair_scores :
                [...currentPres.track_chair_scores.filter(s => s.type.type_id !== score.type.type_id), score]};

            return {...state, presentations: updatePresentations(state.presentations, updatedPres), presentation: updatedPres};
        }
        case RECEIVE_PRESENTATION_AVG_SCORE:{
            const {track_chair_avg_score, track_chair_scores_avg} = payload.response;
            const currentPres = state.presentation;
            const updatedPres = {...currentPres, track_chair_avg_score, track_chair_scores_avg};
            return {...state, presentations: updatePresentations(state.presentations, updatedPres), presentation: updatedPres};
        }
        case DURATION_CHANGED: {
            const {duration} = payload.response;
            const currentPres = state.presentation;
            const updatedPres = {...currentPres, duration};
            return {...state, presentations: updatePresentations(state.presentations, updatedPres), presentation: updatedPres};
        }
        default:
            return state;
    }
};


const updatePresentationStatus = (presentation, selection) => {

    const updatedPres = {
        ...presentation,
        selected: false,
        maybe: false,
        pass: false,
        selection_status: selection,
    };
    // update my selection status for the presentation
    if (selection) {
        updatedPres[selection] = true;
    }
    // do maths ...
    if(selection === 'selected'){
        updatedPres.selectors_count = updatedPres.selectors_count + 1;
    }
    else if(selection === 'maybe'){
        updatedPres.likers_count = updatedPres.likers_count + 1;
    }
    else if(selection === 'pass'){
        updatedPres.passers_count = updatedPres.passers_count + 1;
    }
    // old status
    if(presentation.selected){
        updatedPres.selectors_count = updatedPres.selectors_count - 1;
    }
    else if(presentation.maybe){
        updatedPres.likers_count = updatedPres.likers_count - 1;
    }
    else if(presentation.pass){
        updatedPres.passers_count = updatedPres.passers_count - 1;
    }

    return updatedPres;
};

const updatePresentations = (presentations, presentation) => {
    return presentations.map(p => {
        if (p.id === presentation.id) return {...presentation};
        else return p;
    });
};

const updateSelectionsRemainingForAll = (presentations, oldStatus, newStatus) => {
    let mod = -1;

    if (newStatus !== 'selected') {
        mod = oldStatus === 'selected' ? 1 : 0;
    }

    return presentations.map(p => ({...p, remaining_selections: p.remaining_selections + mod}));
};

const updateSelectionsRemaining = (presentation, oldStatus, newStatus) => {
    let mod = -1;

    if (newStatus !== 'selected') {
        mod = oldStatus === 'selected' ? 1 : 0;
    }

    presentation.remaining_selections = presentation.remaining_selections + mod;

    return presentation;
};

const processPresentation = (p, allTags) => {
    const {selected, maybe, pass, viewed} = p
    let status = '';

    if (selected) status = 'selected';
    if (maybe) status = 'maybe';
    if (pass) status = 'pass';

    allTags = p.tags.reduce((result, tag) => {
        const foundTag = result.find(t => t.value === tag.tag);
        if (foundTag) foundTag.count++;
        else if (tag?.tag) result.push({value: tag.tag, count: 1});

        return result;
    }, allTags);

    return {
        ...p,
        viewed: viewed,
        selected: selected,
        maybe: maybe,
        pass: pass,
        selection_status: status
    };
};

export default presentationsReducer
