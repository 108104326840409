import React from 'react';
import Switch from "react-switch";

import styles from './index.module.scss';

export default ({ flags, actionTypes, onChange, className, presentationId, canEdit }) => {

	return (
		<div className={`${styles.wrapper} ${className} row`}>
			{actionTypes.map(at => {
				const flag = flags.find(f => f.type_id === at.id && f.presentation_id === presentationId);

				return (
					<label className="col-md-6" key={`flag-${at.id}`}>
						<Switch
							onChange={checked => onChange({
									type_id: at.id, 
									is_completed: checked, 
									presentation_id: presentationId, 
									label: at.label
								})
							}
							checked={flag?.is_completed ?? false}
							checkedIcon={false}
							uncheckedIcon={false}
							disabled={!canEdit}
						/>
						<span>{at.label}</span>
					</label>
				)
			})}
		</div>
	);
};