import React from 'react';
import {connect} from 'react-redux';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SortableCard from '../SortableCard';
import ListPlaceholder from '../Placeholder';
import {moveItem} from '../../../utils/methods';
import {reorderSelections} from '../../../actions/selection-actions';
import styles from "./index.module.scss";


class SelectionsList extends React.Component {
    handleUp = (currentIndex) => {
        const {list, type, selections} = this.props;
        const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;

        this.props.reorderSelections(
            list.id,
            type,
            moveItem(selections, currentIndex, newIndex),
        );

        this.props.onDrop();
    };

    handleDown = (currentIndex) => {
        const {list, type, selections} = this.props;
        const newIndex = currentIndex >= selections.length ? currentIndex : currentIndex + 1;

        this.props.reorderSelections(
            list.id,
            type,
            moveItem(selections, currentIndex, newIndex),
        );

        this.props.onDrop();
    };

    handleDrag = (item, fromList, fromIndex, toList, toIndex) => {
        const {list, type, track, selections} = this.props;
        const selectionIds = selections.map(s => s.presentation.id)
        const alreadyExists = selectionIds.includes(item.id);
        const acceptNew = !alreadyExists && (toList === 'interested' || selections.length < track.limit);

        // cannot move out from team list, only reorder
        if (item.originalList === 'team' && toList !== 'team') return;

        // if item is changing column but new column does NOT accept it, then return and do not continue with drag
        if (item.originalList !== toList && !acceptNew) return;

        if (item.originalList !== toList && acceptNew) {
            // if item original list is different from destination list, then is a column change
            this.props.onColumnChange(item, fromList, fromIndex, toList, toIndex);
        } else if (fromIndex !== toIndex) {
            // if the index is changing then reorder the destination list
            const newSelections = moveItem(selections, fromIndex, toIndex);
            this.props.reorderSelections(
                list.id,
                type,
                newSelections
            );
        }

        return;
    };

    handleRemove = (id, index) => {
        const {list, type, selections} = this.props;

        this.props.reorderSelections(
            list.id,
            type,
            selections.filter(s => s.presentation.id !== id),
        );

        this.props.onDrop();
    };

    render() {
        const {list, type, showRank, summit, track, selections} = this.props;

        if(!list) return <div>Not found</div>;

        const sortedSelections = selections.sort((a,b) => a.order - b.order);
        const altThreshold = track.session_count;
        const selectionsCount = sortedSelections.length;

        return (
            <DndProvider backend={HTML5Backend}>
                <div className={styles.wrapper}>
                    {sortedSelections.map((s,i) => (
                        <SortableCard
                            id={s.presentation?.id}
                            key={`sortable-card-${s.presentation?.id}`}
                            index={i}
                            isAlternate={i >= altThreshold}
                            rank={s.order}
                            type={type}
                            listID={list.id}
                            listHash={list.hash}
                            eventKey={i}
                            presentation={s.presentation}
                            summit={summit}
                            track={track}
                            canUp={(s.order > 1 && selectionsCount > 1)}
                            canDown={(s.order < selectionsCount)}
                            showRank={showRank}
                            link={'presentation-link'}
                            onUp={this.handleUp}
                            onDown={this.handleDown}
                            onMove={this.handleDrag}
                            onRemove={this.handleRemove}
                            onDrop={this.props.onDrop}
                        />
                    ))}
                    <ListPlaceholder
                        onMove={this.handleDrag}
                        onDrop={this.props.onDrop}
                        type={type}
                        listID={list.id}
                        listHash={list.hash}
                        lastIdx={selectionsCount === 0 ? 0 : selectionsCount - 1}
                    />
                </div>
            </DndProvider>
        );
    }
}

export default connect(null, {reorderSelections})(SelectionsList);