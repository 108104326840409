import React from 'react';
import Dropdown from '../Dropdown';
import { TagCloud } from "react-tagcloud";

import styles from './index.module.scss';

const TagCloudDropdown = ({value, tags, onTagClick, onClearTag}) => {

    const customRenderer = (tag, size, color) => {
        const selectedStyles = value === tag.value ? {fontWeight: 'bold', color: 'black', textDecoration: 'underline'} : {};
        const style = {color, cursor: 'pointer', fontSize: `${size / 2}em`, ...selectedStyles};
        return (
            <div key={tag.value} style={style} className={styles.tag}>
              {tag.value}
            </div>
        )
    };

    if (!tags.length) return null;

    return (
        <Dropdown id="tag-cloud" className="filter-dropdown" buttonClassName={value ? 'btn-primary' : 'btn-default'} selectedText={<i className='fa fa-cloud' />} shallow>
            <div className={styles.wrapper}>
                {value &&
                    <button className={`${styles.clear} btn btn-default btn-xs`} onClick={() => onClearTag()}>
                        Clear
                    </button>
                }
                <TagCloud
                    minSize={2}
                    maxSize={5}
                    tags={tags}
                    onClick={tag => onTagClick(tag.value)}
                    renderer={customRenderer}
                    className={styles.cloud}
                />
            </div>
        </Dropdown>
    );
};

export default TagCloudDropdown ;