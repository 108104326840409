import React from 'react';

import styles from './index.module.scss';

export default ({ selections }) => {
    const stats = {
        'Beginner': 0,
        'Intermediate': 0,
        'Advanced': 0,
        'N/A': 0
    };

    selections.forEach(s => {
        if (s.presentation) {
            const l = s.presentation.level || 'N/A';
            stats[l]++;
        }
    });

    return (
        <div className={styles.wrapper}>
            <div><strong>Beginner</strong>: {stats.Beginner}</div>
            <div><strong>Intermediate</strong>: {stats.Intermediate}</div>
            <div><strong>Advanced</strong>: {stats.Advanced}</div>
            <div><strong>N/A</strong>: {stats['N/A']}</div>
        </div>
    );
}