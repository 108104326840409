import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import {Modal} from "react-bootstrap";

/**
 * @param text2Copy
 * @param title
 * @param presentationId
 * @param onPostNote
 * @returns {JSX.Element}
 * @constructor
 */
const Copy2ClipBoardButton = ({text2Copy, title, presentationId, onPostNote, justCopy = false}) => {

    const note = useRef('');
    const [show, setShow] = useState(false);

    const onSubmit = () => {
        const noteValue = note.current.value;
        if (noteValue) {
            setShow(false);
            onPostNote(presentationId, noteValue, false);
        }
    };

    const onClick = (ev) => {
        ev.preventDefault();
        navigator.clipboard.writeText(text2Copy);
        if(justCopy) return;
        setShow(true);
    };

    return (
        <>
            <a className={styles.link} onClick={onClick} title={title}>
                <i className="fa fa-clipboard" aria-hidden="true"/>
            </a>
            <Modal show={show} onHide={() => setShow(false)} dialogClassName={styles.modalWrapper}>
                <Modal.Header closeButton>
                    <Modal.Title>What is this email about?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Email Addresses were copied to clipboard.</p>
                    <p>Please add an activity note with a brief description of what the email involves.</p>
                    <textarea ref={note} />
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={() => onSubmit()}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


Copy2ClipBoardButton.propTypes = {
    text2Copy: PropTypes.string.isRequired,
    title: PropTypes.string,
    presentationId: PropTypes.number,
    onPostNote: PropTypes.func,
};

Copy2ClipBoardButton.defaultProps = {
    title: 'Copy to clipboard'
};

export default Copy2ClipBoardButton;