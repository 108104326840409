/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
import React from 'react';
import {connect} from "react-redux";
import {FreeTextSearch} from 'openstack-uicore-foundation/lib/components';
import { Pagination } from 'react-bootstrap';
import {getChangeRequests, resolveChangeRequest} from "../actions/change-requests-actions";
import Table from '../components/ChangeRequestsTable/Table';
import RejectModal from '../components/RejectModal';

import styles from "../styles/change-requests-page.module.scss";


class ChangeRequestsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            rejectChangeRequest: null
        }
    }

    componentDidMount() {
        this.props.getChangeRequests();
    }

    handleSort = (index, key, dir, func) => {
        const {search, page, perPage} = this.props;
        this.props.getChangeRequests(search, page, perPage, key, dir);
    };

    handleSearch = (searchTerm) => {
        const {page, perPage, order, orderDir} = this.props;
        this.props.getChangeRequests(searchTerm, page, perPage, order, orderDir);
    };

    handlePageChange = (page) => {
        const {search, perPage, order, orderDir} = this.props;
        this.props.getChangeRequests(search, page, perPage, order, orderDir);
    };

    handleApprove = (id) => {
        const {changeRequests} = this.props;
        const request = changeRequests.find(cr => cr.id === id);
        this.props.resolveChangeRequest(request.presentation.id, id, true, '');
    };

    handleReject = (id) => {
        const {changeRequests} = this.props;
        const request = changeRequests.find(cr => cr.id === id);

        this.setState({showModal: true, rejectChangeRequest: request});
    };

    submitReject = (reason) => {
        const {presentation, id} = this.state.rejectChangeRequest;

        this.props.resolveChangeRequest(presentation.id, id, false, reason);
        this.closeModal();
    };

    closeModal = () => {
      this.setState({showModal: false, rejectChangeRequest: null})
    };

    render() {
        const {changeRequests, lastPage, page, perPage, search, totalRequests, order, orderDir} = this.props;
        const {showModal} = this.state;

        const columns = [
            { columnKey: 'id', value: "Id", sortable: true },
            { columnKey: 'presentation_title', value: "Presentation", sortable: true },
            { columnKey: 'status', value: "Status" },
            { columnKey: 'old_category_name', value: "Old Category", sortable: true },
            { columnKey: 'new_category_name', value: "New Category", sortable: true },
            { columnKey: 'requester_fullname', value: "Requester", sortable: true },
        ];

        const options = {
            sortCol: order,
            sortDir: orderDir,
            className: "table-bordered",
            actions: {
                onApprove: this.handleApprove,
                onReject: this.handleReject,
            }
        };

        if (changeRequests.length === 0) {
            return (
                <div className={styles.wrapper}>
                    <p className={styles.noRequests}>There are no change requests at the moment.</p>
                </div>
            );
        }

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.searchWrapper}>
                        <FreeTextSearch value={search} onSearch={this.handleSearch} placeholder="Search presentation" />
                    </div>
                </div>
                <div className={styles.body}>
                    <Table
                        options={options}
                        data={changeRequests}
                        columns={columns}
                        onSort={this.handleSort}
                    />
                    <div className={styles.paginationWrapper}>
                        {totalRequests > perPage &&
                        <Pagination
                            bsSize="medium"
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={10}
                            items={lastPage}
                            activePage={page}
                            onSelect={this.handlePageChange}
                        />
                        }
                    </div>
                </div>
                <RejectModal show={showModal} onClose={this.closeModal} onSubmit={this.submitReject} />
            </div>
        );
    }
}

const mapStateToProps = ({ baseState, changeRequestsState, loggedUserState }) => ({
    ...baseState,
    ...changeRequestsState,
    loggedUser: loggedUserState.member
});

export default connect(
    mapStateToProps,
    {
        getChangeRequests,
        resolveChangeRequest
    }
)(ChangeRequestsPage);
