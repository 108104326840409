import React, {useRef} from 'react';
import {Modal} from "react-bootstrap";

import styles from './index.module.scss';

export default ({ show, onClose, onSubmit }) => {
	const reason = useRef('');

	return (
		<Modal show={show} onHide={onClose} dialogClassName={styles.wrapper}>
			<Modal.Header closeButton>
				<Modal.Title>Track Change Rejection</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Please state a reason why you reject this change:</p>
				<textarea ref={reason} />
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-default" onClick={() => onSubmit(reason.current.value)}>
					Submit
				</button>
			</Modal.Footer>
		</Modal>
	);
};