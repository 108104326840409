/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

 import moment from 'moment-timezone';
 import URI from "urijs";
 import validator from 'validator';
import {adminGroups, superAdmin} from "../model/groups";
import {getAccessToken} from 'openstack-uicore-foundation/lib/security/methods'
import { initLogOut} from 'openstack-uicore-foundation/lib/security/methods';
import trackChair from "../model/TrackChair";
 
 
 export const findElementPos = (obj) => {
     var curtop = -70;
     if (obj.offsetParent) {
         do {
             curtop += obj.offsetTop;
         } while (obj = obj.offsetParent);
         return [curtop];
     }
 }
 
 export const epochToMoment = (atime) => {
     if(!atime) return atime;
     atime = atime * 1000;
     return moment(atime);
 }
 
 export const epochToMomentTimeZone = (atime, time_zone) => {
     if(!atime) return atime;
     atime = atime * 1000;
     return moment(atime).tz(time_zone);
 }
 
 export const formatEpoch = (atime, format = 'M/D/YYYY h:mm a') => {
     if(!atime) return atime;
     return epochToMoment(atime).format(format);
 }
 
 export const nowBetween = (start, end) => {
     const now = moment().valueOf();
     return now > (start * 1000) && now < (end * 1000);
 }
 
 export const nowAfter = (date) => {
     const now = moment().valueOf();
     return now > (date * 1000);
 }
 
 export const objectToQueryString = (obj) => {
     var str = "";
     for (var key in obj) {
         if (str != "") {
             str += "&";
         }
         str += key + "=" + encodeURIComponent(obj[key]);
     }
 
     return str;
 }
 
 export const getBackURL = () => {
     let url      = URI(window.location.href);
     let query    = url.search(true);
     let fragment = url.fragment();
     let backUrl  = query.hasOwnProperty('BackUrl') ? query['BackUrl'] : null;
     if(fragment != null && fragment != ''){
         backUrl += `#${fragment}`;
     }
     return backUrl;
 }
 
 export const scrollToError = () => {
     let firstError = document.getElementsByClassName("error-label")[0];
     if (firstError) {
         firstError.scrollIntoView({behavior: "smooth", block: "center"});
     }
 }
 
 export const validate = (entity, rules, errors) => {
     let result = true;
 
     for (var field in rules) {
 
         if (rules[field].hasOwnProperty('required')) {
             if (typeof entity[field] == 'string') {
                 var stripedHtml = entity[field].replace(/<[^>]+>/g, '');
                 if (!stripedHtml) {
                     errors[field] = rules[field].required;
                     result = false;
                 }
             } else if (!entity[field] || entity[field].length == 0) {
                 errors[field] = rules[field].required;
                 result = false;
             }
         }
 
         if (rules[field].hasOwnProperty('email')) {
             if (entity[field] && !validator.isEmail(entity[field])) {
                 errors[field] = rules[field].email;
                 result = false;
             }
         }
 
         if (rules[field].hasOwnProperty('maxLength')) {
             if (entity[field].length > 0 && entity[field].length > rules[field].maxLength.value) {
                 errors[field] = rules[field].maxLength.msg;
                 result = false;
             }
         }
 
         if (rules[field].hasOwnProperty('link')) {
             if (entity[field] && !validator.isURL(entity[field])) {
                 errors[field] = rules[field].link;
                 result = false;
             }
         }
 
         if (rules[field].hasOwnProperty('links')) {
             for (let [idx, link] of entity[field].entries()) {
                 if (link && !validator.isURL(link,{require_protocol: true})) {
                     errors['link_'+idx] = rules[field].links;
                     result = false;
                     break;
                 }
             }
         }
 
         if (rules[field].hasOwnProperty('title_link')) {
             for (let link of entity[field]) {
                 if (link.link && !validator.isURL(link.link,{protocols: ['http', 'https'], require_protocol:true})) {
                     errors[field] = rules[field].title_link;
                     result = false;
                     break;
                 }
             }
         }
 
     }
 
     return result;
 }
 
 
 export const moveItem = (array, fromIndex, toIndex) => {
     if (fromIndex === toIndex) return array;
 
     const newArray = [...array];
 
     const target = newArray[fromIndex];
     const inc = toIndex < fromIndex ? -1 : 1;
 
     for (let i = fromIndex; i !== toIndex; i += inc) {
         newArray[i] = newArray[i + inc];
     }
 
     newArray[toIndex] = target;
 
     return newArray;
 };
 
 export const getInitials = string => {
     return string.split(" ").map(n => n[0]).join("");
 };

 export const isSuperAdmin = (member) => {
     return member.groups.some(g => superAdmin.includes(g.code));
 };

export const isAdmin = (member) => {
    return !!member?.groups?.some(g => adminGroups.includes(g.code));
};

export const getAccessTokenSafely = async () => {
  try {
    return await getAccessToken();
  }
  catch (e) {
    console.log('log out: ', e);
    initLogOut();
  }
};

/**
 * @param person
 * @returns {`${*} ${*} ${string}`}
 */
export const formatPersonFullName = (person) => {
    return `${person.first_name} ${person.last_name}`;
}

/**
 * @param person
 * @param showCompany
 * @returns {`${*} ${*} ${string} <${string}>`}
 */
export const formatPersonEmailRFC = (person, showCompany = false) => {
    return `${formatPersonFullName(person, showCompany)} <${person.email}>`;
}

export const getAvailableTracksFromSelectionplan = (sp) => {
    // get all tracks from the selection plan and track visible
    return sp?.track_groups.reduce((result, trackGroup) => {
        trackGroup?.tracks?.forEach(track => {
            if (!result.find(tr => tr.id === track.id)) {
                result.push(track);
            }
        });
        return result;
    }, []).filter(t => t?.chair_visible);
}

export const getTrackForuser = (sp, member, loggedTrackChair) => {
    // selection plan does no has any track group
    if(!sp.track_groups) return [];
    const spTracks = getAvailableTracksFromSelectionplan(sp);
    if(!spTracks.length) return [];

    const memberIsAdmin = member.groups.some(g => adminGroups.includes(g.code));
    return memberIsAdmin ? spTracks : spTracks.filter(t => !!loggedTrackChair?.categories?.includes(t.id));
}

export const canAccessToSelectionPlan = (sp, member, loggedTrackChair) => {
    // selection plan does no has any track group
    return getTrackForuser(sp, member, loggedTrackChair).length;
}