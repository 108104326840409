/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React from 'react'
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import SummitLayout from "./summit-layout";
import LandingPage from "../pages/landing-page";
import {adminGroups} from "../model/groups";

const PrimaryLayout = ({ isLoggedUser, member, match }) => {
    const isAdmin = member && member.groups.some(g => adminGroups.includes(g.code));
    const isTrackChair = isLoggedUser && (member.track_chairs.length > 0 || isAdmin);

    if (!isTrackChair) return <Redirect to="/unauthorized" />;

    return(
      <Switch>
          <Route strict exact path={match.url} component={LandingPage}/>
          <Route path={`${match.url}/:summit_slug`} component={SummitLayout}/>
          <Route render={props => (<Redirect to={`/app`}/>)}/>
      </Switch>
    );
}

const mapStateToProps = ({ loggedUserState, baseState }) => ({
    member: loggedUserState.member,
    isLoggedUser: loggedUserState.isLoggedUser,
    selectionPlan: baseState.selectionPlan,
    loading: baseState.loading
});

export default connect( mapStateToProps, {})(PrimaryLayout)


