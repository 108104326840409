/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import {LOGOUT_USER} from "openstack-uicore-foundation/lib/security/actions";
import {
    REQUEST_TRACK_CHAIRS,
    RECEIVE_TRACK_CHAIRS,
    REQUEST_LISTS,
    RECEIVE_LISTS,
    REORGANISE_SELECTIONS,
    REQUEST_TEAM_LIST,
    RECEIVE_TEAM_LIST,
    SAVING_LIST,
    LIST_SAVED
} from "../actions/selection-actions";
import {MARK_SELECTION, SELECTION_CLEARED} from "../actions/presentation-actions";


const DEFAULT_STATE = {
    individualList: {
        id: 0,
        selected_presentations: [],
        interested_presentations: [],
        hash: '',
        isSelectedDirty: false,
        isInterestedDirty: false
    },
    teamList: {
        id: 0,
        selected_presentations: [],
        hash: '',
        isDirty: false
    },
    track: null,
    trackChairs: [],
    trackChair: null,
};

const selectionsReducer = (state = DEFAULT_STATE, action) => {
    const { type, payload } = action;

    switch(type){
        case LOGOUT_USER:
            return DEFAULT_STATE;
        case REQUEST_TRACK_CHAIRS: {
            const {track} = payload;
            track.limit = track.session_count + track.alternate_count;
            return {...state, track, trackChair: null, trackChairs: []};
        }
        case RECEIVE_TRACK_CHAIRS: {
            const {data} = payload.response;
            return {...state, trackChairs: data };
        }
        case REQUEST_LISTS: {
            const {trackChair} = payload;
            return {...state, trackChair, individualList: DEFAULT_STATE.individualList};
        }
        case RECEIVE_LISTS: {
            const individualList = payload.response;

            return {...state, individualList};
        }
        case REQUEST_TEAM_LIST: {
            return {...state, teamList: DEFAULT_STATE.teamList};
        }
        case RECEIVE_TEAM_LIST: {
            const teamList = payload.response;

            return {...state, teamList};
        }
        case SELECTION_CLEARED:
        case MARK_SELECTION: {
            // clear track chair so it reloads, just in case the change was to the loaded list.
            return {...state, trackChair: null}
        }
        case SAVING_LIST: {
            const {listId, collection} = payload;
            const {individualList, teamList} = state;
            const isDirty = listId === teamList.id ? false : teamList.isDirty;
            const isSelectedDirty = listId === individualList.id && collection === 'selected' ? false : individualList.isSelectedDirty;
            const isInterestedDirty = listId === individualList.id && collection === 'maybe' ? false : individualList.isInterestedDirty;

            return {
                ...state,
                individualList: {...state.individualList, isInterestedDirty, isSelectedDirty},
                teamList: {...state.teamList, isDirty}
            };
        }
        case LIST_SAVED: {
            const list = payload.response;

            if (list.type === 'Group') {
                return {
                    ...state,
                    teamList: {...state.teamList, hash: list.hash}
                };
            }

            return state;
        }
        case REORGANISE_SELECTIONS: {
            const {listId, type, selections} = payload;

            const organisedSelections = selections.map((s,i) => (
                {...s, order: i+1}
            ));

            switch (type) {
                case 'team' :
                    return {...state, teamList: {...state.teamList, selected_presentations: organisedSelections, isDirty: true}};
                case 'selected':
                    if (listId !== state.individualList.id) return state;
                    return {...state, individualList: {...state.individualList, selected_presentations: organisedSelections, isSelectedDirty: true}};
                case 'interested':
                    if (listId !== state.individualList.id) return state;
                    return {...state, individualList: {...state.individualList, interested_presentations: organisedSelections, isInterestedDirty: true}};
            }
        }
        default:
            return state;
    }
};

export default selectionsReducer
