import React, {createRef} from 'react';
import {Row, Col} from 'react-bootstrap';
import {RawHTML} from 'openstack-uicore-foundation/lib/components'
import {Maybe, Selected, Score, PopularityScore, Pass} from '../../Icons';
import CategoryDropdown from "../../TrackDropdown";
import ProgressFlags from '../ProgressFlags';

;
import PresentationRating from "../../PresentationRating";

import styles from './index.module.scss';
import {formatPersonEmailRFC, formatPersonFullName} from "../../../utils/methods";
import Copy2ClipBoardButton from "../../Copy2ClipBoardButton";

export default class PresentationMeta extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showChangeRequest: false,
            selectedCategory: null,
        };

        this.durationRef = createRef();
    }

    toggleCategoryChange = ev => {
        ev.preventDefault();
        this.setState({
            showChangeRequest: !this.state.showChangeRequest
        });
    };

    selectCategory = id => {
        this.setState({
            selectedCategory: id
        });
    };

    onSubmit = ev => {
        const {selectedCategory} = this.state;
        const {presentation, allTracks} = this.props;

        ev.preventDefault();
        this.toggleCategoryChange(ev);

        const selectedTrack = allTracks.find(t => t.slug === selectedCategory);

        this.props.onCategoryChange(presentation.id, selectedTrack.id);
    };

    handleDurationChange = () => {
        const {presentation} = this.props;
        const duration = this.durationRef.current.value;
        this.props.onDurationChange(presentation.id, duration)
    };


    render() {

        const {
            presentation,
            allTracks,
            track,
            canEdit,
            memberAdmin,
            actionTypes,
            selectionPlan,
            onChangeRating,
            onClearRating
        } = this.props;
        const {selectedCategory, showChangeRequest} = this.state;
        const availableTracks = allTracks.filter(t => t.id !== track.id);
        const pendingChangeRequests = presentation.category_changes_requests?.filter(cr => !cr.approval_date) || [];
        const changeRequested = pendingChangeRequests.length > 0;
        const canRequestChange = !!selectionPlan?.allow_track_change_requests;

        return (
            <div className={styles.wrapper}>
                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <dl className="dl-horizontal">
                            <dt>Submitted by:</dt>
                            <dd>
                                {presentation.creator && canEdit ? (
                                    <>
                                      <span title={formatPersonEmailRFC(presentation.creator)}>
                                        <i className="fa fa-envelope"/>&nbsp;{formatPersonFullName(presentation.creator)}
                                      </span>&nbsp;<Copy2ClipBoardButton
                                                            onPostNote={this.props.onPostNote}
                                                            presentationId={presentation.id}
                                                            text2Copy={formatPersonEmailRFC(presentation.creator)}/>
                                    </>
                                ) : (
                                    <span>{presentation.creator ?
                                        <>
                                        <span title={formatPersonEmailRFC(presentation.creator)}>
                                            <i className="fa fa-envelope"/>&nbsp;{formatPersonFullName(presentation.creator, true)}
                                            &nbsp;<Copy2ClipBoardButton
                                            justCopy={true}
                                            text2Copy={formatPersonEmailRFC(presentation.creator)}/>
                                        </span>
                                        </>
                                        : 'N/A'}
                                    </span>
                                )}
                            </dd>
                            {presentation.creator && presentation.creator?.company &&
                            <>
                                <dt>Company:</dt>
                                <dd>{presentation.creator?.company}</dd>
                            </>
                            }
                            <dt>Type:</dt>
                            <dd>{presentation.type.name}</dd>
                            <dt>Level:</dt>
                            <dd>{presentation.level}</dd>
                            <dt>Track:</dt>
                            <dd>
                                {track.name}
                                {presentation.moved_to_category &&
                                <span key='moved' className="detail-presentation-metric moved">
                                  <i className="fa fa-exchange"/>
                                </span>
                                }
                                <br/>
                                {canEdit && canRequestChange && !changeRequested &&
                                <>(<a href="#" onClick={this.toggleCategoryChange}>Request category change</a>)</>
                                }
                                {changeRequested &&
                                <small>(category change requested)</small>
                                }
                            </dd>
                            <div className={styles.durationBox}>
                                <dt>Duration (minutes):</dt>
                                <dd>
                                    {canEdit ? (<div className="input-group input-group-sm">
                                            <input
                                                type="number"
                                                disabled={!canEdit && !memberAdmin}
                                                ref={this.durationRef}
                                                className="form-control"
                                                min="0"
                                                step="5"
                                                defaultValue={presentation.duration / 60}
                                            />
                                            <span className="input-group-btn">
										 <button
                                             className="btn btn-default"
                                             type="button"
                                             onClick={this.handleDurationChange}
                                             disabled={!canEdit && !memberAdmin}
                                         >
                       Set
                     </button>
									 </span>
                                        </div>)
                                        : presentation.duration / 60}
                                </dd>
                            </div>
                        </dl>
                    </Col>
                    <Col md={6} xs={12} sm={12} id="cluster_info">
                        <dl className="dl-horizontal">
                            {canEdit &&
                            <PresentationRating
                                presentation={presentation}
                                selectionPlan={selectionPlan}
                                onChangeRating={onChangeRating}
                                onClearRating={onClearRating}
                            />
                            }
                        </dl>
                    </Col>
                </Row>
                {showChangeRequest &&
                <Row>
                    <Col md={8} mdOffset={2}>
                        <div className={styles.changeRequestWrapper}>
                            <form onSubmit={this.onSubmit}>
                                <div className="input-group presentation-search">
                                    <CategoryDropdown onSelect={this.selectCategory} value={selectedCategory}
                                                      tracks={availableTracks}/>
                                    <span className="input-group-btn">
										<button type="submit" className="btn btn btn-primary">
											<i className="fa fa-check"/> Submit request
										</button>
										<button onClick={this.toggleCategoryChange} type="button"
                                                className="btn btn-default">
											<i className="fa fa-ban"/> Cancel
										</button>
									</span>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
                }
                <Row className={styles.statsWrapper}>
                    <Col md={2}>
                        <div className={`${styles.votes} ${styles.box}`}>
                            <h5>Community Vote</h5>
                            <h1>{presentation.vote_average ? presentation.vote_average.toFixed(2) : '0.00'}</h1>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={`${styles.selections} ${styles.box}`}>
                            <h5><small className={styles.icon}><Selected/></small> Selections</h5>
                            <h1>{presentation.selectors_count ? presentation.selectors_count : 0}</h1>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={`${styles.interested} ${styles.box}`}>
                            <h5><small className={styles.icon}><Maybe/></small> Interested</h5>
                            <h1>{presentation.likers_count ? presentation.likers_count : 0}</h1>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={`${styles.no_thanks} ${styles.box}`}>
                            <h5><small className={styles.icon}><Pass/></small> NO THANKS</h5>
                            <h1>{presentation.passers_count ? presentation.passers_count : 0}</h1>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={`${styles.score} ${styles.box}`}>
                            <h5><small className={styles.icon}><Score/></small> SCORE</h5>
                            <h1>{presentation.track_chair_avg_score ? presentation.track_chair_avg_score : 0}</h1>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={`${styles.popularity} ${styles.box}`}>
                            <h5><small className={styles.icon}><PopularityScore/></small> POPULARITY SCORE</h5>
                            <h1>{presentation.popularity_score ? presentation.popularity_score : 0}</h1>
                        </div>
                    </Col>
                </Row>
                {actionTypes.length > 0 &&
                <div className={styles.section}>
                    <h3>Status</h3>
                    <ProgressFlags
                        flags={presentation.actions}
                        actionTypes={actionTypes}
                        canEdit={canEdit}
                        onChange={this.props.onFlagChange}
                        presentationId={presentation.id}
                    />
                </div>
                }
                {presentation.description &&
                <div className={styles.section}>
                    <h3>Description</h3>
                    <RawHTML>{presentation.description}</RawHTML>
                </div>
                }
                {presentation.social_description &&
                <div className={styles.section}>
                    <h3>Social Description</h3>
                    <RawHTML>{presentation.social_description}</RawHTML>
                </div>
                }
                {presentation.attendees_expected_learnt &&
                <div className={styles.section}>
                    <h3>What Should Attendees Expect to Learn?</h3>
                    <RawHTML>{presentation.attendees_expected_learnt}</RawHTML>
                </div>
                }
                {presentation.slides && presentation.slides.length > 0 &&
                <div className={styles.section}>
                    <h3>Presentation Material</h3>
                    <ul>
                        {presentation.slides.map(f =>
                            <li key={`pres_slide_${f.id}`}>
                                <a href={f.url} target="_blank">{f.name}</a>
                            </li>
                        )}
                    </ul>
                </div>
                }

                {presentation.links && presentation.links.length > 0 &&
                <div className={styles.section}>
                    <h3>Related Links</h3>
                    <ul>
                        {presentation.links.map(l =>
                            <li key={`pres_link_${l.id}`}>
                                <a href={l.link} target="_blank">{l.link}</a>
                            </li>
                        )}
                    </ul>
                </div>
                }
            </div>
        );
    }
}