/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
import React from 'react';
import T from 'i18n-react/dist/i18n-react'
import TrackChairDropdown from "../components/TrackChairDropdown";
import TrackDropdown from "../components/TrackDropdown";
import {connect} from "react-redux";
import {getTrackChairs, getSelectionLists, reorderSelections, getTeamList, saveLists} from "../actions/selection-actions";
import history from "../history";
import {moveItem} from '../utils/methods';
import SelectionList from '../components/SelectionList/Sortable';
import StaticSelectionList from '../components/SelectionList/Static';
import SelectionStats from '../components/SelectionStats';
import {Maybe, Selected, Team, Help} from '../components/Icons';

import styles from "../styles/selections-page.module.scss";


class SelectionsPage extends React.Component {

    componentDidMount() {
        this.onPageLoad(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onPageLoad();
    }

    onPageLoad = (forcePull = false) => {
        const {tracksForUser, match, loggedUser, trackChairs, trackChair, allTracks} = this.props;
        let { track } = this.props;

        if (tracksForUser.length > 0) {
            const {track_slug} = match.params;
            const trackLoaded = track && track.slug === track_slug;
            const canEdit = track ? tracksForUser.map(t => t.id).includes(track.id) : false;

            if (!track_slug || (trackLoaded && !canEdit)) {
                this.selectTrack(tracksForUser[0].slug);
            } else if (!track || !trackLoaded || forcePull) {
                this.props.getTrackChairs(track_slug).then(() => {
                    // this is done bc former action has a side effect (sets track on reducer if does not exists)
                    // and returns the track chairs 
                    track = allTracks.find(c => c.slug === track_slug)
                    if(!track)
                        return;
                    this.props.getTeamList(track.id);
                });
                return;
            }
            
        }

        if (trackChairs.length > 0) {
            const {member_id} = match.params;
            const memberInList = trackChairs.map(tc => `${tc.member.id}`).includes(member_id);

            if (!member_id || !memberInList) {
                const selectTC = trackChairs.find(tc => tc.member.id === loggedUser.id) ? loggedUser.id : trackChairs[0].member.id;
                this.selectTrackChair(selectTC);
            } else if (!trackChair || member_id !== `${trackChair.member.id}`) {
                this.props.getSelectionLists(track.id, member_id);
            }
        }
    };

    selectTrack = trackSlug => {
        const {selectionPlan, summit} = this.props;
        history.push(`/app/${summit.slug}/${selectionPlan.id}/selections/${trackSlug}`);
    };

    selectTrackChair = trackChairId => {
        const {selectionPlan, summit, track} = this.props;
        history.push(`/app/${summit.slug}/${selectionPlan.id}/selections/${track.slug}/${trackChairId}`);
    };

    handleColumnChange = (item, fromListName, fromIndex, toListName, toIndex) => {
        const {individualList, teamList} = this.props;
        const {presentation} = item;
        const isFromTeamList = fromListName === 'team';
        const isToTeamList = toListName === 'team';
        const fromList = isFromTeamList ? teamList.selected_presentations : individualList[`${fromListName}_presentations`];
        const toList = isToTeamList ? teamList.selected_presentations : individualList[`${toListName}_presentations`];
        const toListId = isToTeamList ? teamList.id : individualList.id;

        // Team is a clone. Shouldn't remove from the other piles unless presentation is new in list and undropped
        if(!isToTeamList) {
            // Remove from the old list
            this.props.reorderSelections(toListId, fromListName, fromList.filter(s => s.presentation.id !== item.id));
        }

        if(toList.find(i => i.presentation.id === item.id)) {
            // List already has it.
            return;
        }

        const newList = [
            ...toList,
            {
                id: item.id,
                listId: toListId,
                presentation,
                order: item.order,
                type: toListName
            }
        ];

        const newListOrdered = moveItem(newList, (newList.length-1), toIndex);

        // TODO me falta el hash en team LIST !!!  y no se pq esta mandando la colection vacia
        // TODO hay algo tambien con los item id (deberia ser la listID o la presentation id)

        this.props.reorderSelections(toListId, toListName, newListOrdered);
    };

    handleDrop = () => {
        this.props.saveLists();
    };

    getLists = () => {
        const {selectionOpen, summit, track, trackChair, trackChairs, individualList, teamList, loggedUser} = this.props;
        const {selected_presentations, interested_presentations} = individualList;

        if (!trackChair) return null;

        const myListLength = selected_presentations?.length || 0;
        const trackLimit = track.session_count + track.alternate_count;
        const teamListLength = teamList?.selected_presentations?.length || 0;
        const myListFull = trackLimit === myListLength;
        const teamListFull = trackLimit === teamListLength;
        const userOwnsList = loggedUser.id === trackChair.member.id;

        const canEditIndividual = !!selectionOpen && userOwnsList;
        const canEditTeam = !!selectionOpen && userOwnsList;

        const IndividualListComponent = canEditIndividual ? SelectionList : StaticSelectionList;
        const TeamListComponent = canEditTeam ? SelectionList : StaticSelectionList;

        if (!trackChairs.length) {
            return (<div className={styles.noTrackChairs}>There are no Track Chairs for this track.</div>);
        }

        return (
            <div className={styles.listsWrapper}>
                <div className={`${styles.list} ${styles.interested}`}>
                    <h3>
                        <Maybe /> {T.translate("selections.interested")} <Help tooltip={T.translate("selections.interested_info")} /> ({interested_presentations.length})
                    </h3>
                    <SelectionStats selections={interested_presentations} />
                    <IndividualListComponent
                        selections={interested_presentations}
                        list={individualList}
                        teamList={teamList}
                        showRank={false}
                        type='interested'
                        track={track}
                        summit={summit}
                        onColumnChange={this.handleColumnChange}
                        onDrop={this.handleDrop}
                    />
                </div>
                <div className={`${styles.list} ${styles.selected}`}>
                    <h3>
                        <Selected /> {T.translate("selections.selections")} <Help tooltip={T.translate("selections.selections_info")} /> ({myListLength} / {trackLimit})
                    </h3>
                    {myListFull && <span className={styles.fullLabel}>FULL</span>}
                    <SelectionStats selections={selected_presentations} />
                    <IndividualListComponent
                        list={individualList}
                        selections={selected_presentations}
                        teamList={teamList}
                        type='selected'
                        track={track}
                        summit={summit}
                        onColumnChange={this.handleColumnChange}
                        onDrop={this.handleDrop}
                    />
                </div>
                <div className={`${styles.list} ${styles.team}`}>
                    <h3>
                        <Team /> {T.translate("selections.team")} <Help tooltip={T.translate("selections.team_info")} /> ({teamListLength} / {trackLimit})
                    </h3>
                    {teamListFull && <span className={styles.fullLabel}>FULL</span>}
                    <SelectionStats selections={teamList.selected_presentations} />
                    <TeamListComponent
                        list={teamList}
                        selections={teamList.selected_presentations}
                        teamList={teamList}
                        type='team'
                        track={track}
                        summit={summit}
                        onColumnChange={this.handleColumnChange}
                        onDrop={this.handleDrop}
                    />
                </div>
            </div>
        );
    };

    render() {
        const {tracksForUser, track, trackChair, trackChairs} = this.props;

        if (!track || !trackChairs) return null;

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.filterWrapper}>
                        <span>Track: </span>
                        <TrackDropdown value={track.slug} tracks={tracksForUser} onSelect={this.selectTrack} />
                    </div>
                    <div className={styles.filterWrapper}>
                        <span>Track Chair: </span>
                        <TrackChairDropdown value={trackChair?.member?.id} trackChairs={trackChairs} onSelect={this.selectTrackChair} />
                    </div>
                    <div className={styles.trackLimits}>
                        <div><span>Selection max:</span> {track.session_count}</div>
                        <div><span>Alternate max:</span> {track.alternate_count}</div>
                    </div>
                </div>
                {this.getLists()}
            </div>
        );
    }
}

const mapStateToProps = ({ baseState, selectionsState, loggedUserState }) => ({
    ...baseState,
    ...selectionsState,
    loggedUser: loggedUserState.member
});

export default connect(
    mapStateToProps,
    {
        getTrackChairs,
        getTeamList,
        getSelectionLists,
        reorderSelections,
        saveLists
    }
)(SelectionsPage);
