/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import {
    getRequest,
    putRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler,
    escapeFilterValue
} from "openstack-uicore-foundation/lib/utils/actions";
import {getAccessTokenSafely} from "../utils/methods";


export const REQUEST_CHANGE_REQUESTS        = 'REQUEST_CHANGE_REQUESTS';
export const RECEIVE_CHANGE_REQUESTS        = 'RECEIVE_CHANGE_REQUESTS';
export const CHANGE_REQUEST_RESOLVED        = 'CHANGE_REQUEST_RESOLVED';


export const getChangeRequests = ( search = '', page = 1, perPage = 10, order = 'id', orderDir = 0 ) => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit, selectionPlan } = baseState;
    const accessToken = await getAccessTokenSafely();
    const filter = [];

    dispatch(startLoading());

    if (search) {
        const escapedTerm = escapeFilterValue(search);
        filter.push(`new_category_name=@${escapedTerm},old_category_name=@${escapedTerm},presentation_title=@${escapedTerm}`);
    }

    const params = {
        access_token : accessToken,
        page         : page,
        per_page     : perPage,
        expand: 'presentation, new_category, old_category, requester, aprover',
    };

    if(filter.length > 0){
        params['filter[]']= filter;
    }

    // order
    if(order != null && orderDir != null){
        const orderDirSign = (orderDir === 1) ? '+' : '-';
        params['order']= `${orderDirSign}${order}`;
    }

    return getRequest(
        createAction(REQUEST_CHANGE_REQUESTS),
        createAction(RECEIVE_CHANGE_REQUESTS),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/all/category-change-requests`,
        authErrorHandler,
        {search, page, perPage, order, orderDir}
    )(params)(dispatch).then(() => {
        dispatch(stopLoading())
    });
};

export const resolveChangeRequest = (presentationId, changeRequestId, approved, reason) => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit, selectionPlan } = baseState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token : accessToken,
        expand: 'presentation, new_category, old_category, requester, aprover',
    };

    putRequest(
        null,
        createAction(CHANGE_REQUEST_RESOLVED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${presentationId}/category-change-requests/${changeRequestId}`,
        {approved, reason},
        authErrorHandler,
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

