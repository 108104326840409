import React from 'react';
import Dropdown from '../drop-down';

const TrackDropdown = ({
                         tracks,
                         value,
                         onSelect,
                         valueKey = 'slug'
                       }) => {
  
  tracks.sort((a, b) => {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();
    
    return aName > bName ? 1 : (aName < bName ? -1 : 0)
  });
  
  const options = tracks.map(c => ({value: c[valueKey], label: c.name}));
  
  return (
    <Dropdown
      id="track"
      value={value}
      placeholder="Select a Track"
      options={options}
      onChange={ev => onSelect(ev.target.value)}
    />
  );
};

export default TrackDropdown;