import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import styles from "./index.module.scss";


const ListPlaceholder = ({type, listID, listHash, onMove, onDrop, lastIdx}) => {
    const [collectedProps, drop] = useDrop(() => ({
        accept: 'card',
        hover(props, monitor, component) {
            const item = monitor.getItem();
            const hoverList = type;
            const originalList = item.originalList;

            // prevent moving from team to another list
            if (originalList === 'team' && hoverList !== 'team') return;

            onMove(item, item.type, item.index, hoverList, lastIdx);
            item.index = lastIdx;
            item.type = hoverList;
            item.targetListID = listID;
            item.targetListHash = listHash;

        },
        canDrop(item, monitor) {
            return !(type === 'team' && monitor.getItem().type !== 'team');
        },
        drop(item, monitor) {
            if (monitor.canDrop()) {
                onDrop()
            }
        },
    }), [lastIdx]);

    return (
        <div ref={drop} className={styles.wrapper} />
    );
}

ListPlaceholder.propTypes = {
    onMove: PropTypes.func.isRequired
};

export default ListPlaceholder;