import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import Card from '../Card';

const SortableCard = (props) => {
    const {id, index, listID, listHash, type, presentation, rank, onMove, onDrop} = props;
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            const dragList = item.type;
            const hoverList = type;
            const originalList = item.originalList;

            // prevent moving from team to another list
            if (originalList === 'team' && hoverList !== 'team') return;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex && dragList === hoverList) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && dragList === hoverList) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && dragList === hoverList) {
                return;
            }
            // Time to actually perform the action
            onMove(item, dragList, dragIndex, hoverList, hoverIndex);
            item.index = hoverIndex;
            item.type = hoverList;
            item.targetListID = listID;
            item.targetListHash = listHash;
        },
        canDrop(item, monitor) {
            return !(type === 'team' && monitor.getItem().type !== 'team');
        },
        drop(item, monitor) {
            if (monitor.canDrop()) {
                onDrop()
            }
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ id, index, presentation, type, order: rank, originalList: type }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;

    if (!presentation) return null;

    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity, marginBottom: 10 }} data-handler-id={handlerId}>
            <Card {...props} sortable />
        </div>
    );
}

SortableCard.propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.any.isRequired,
    onMove: PropTypes.func.isRequired,
};

export default SortableCard;