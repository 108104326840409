import React from 'react';
import {Row, Col} from 'react-bootstrap';

import styles from "./index.module.scss";

export default ({canEdit, index, total}) => (
    <div className={styles.wrapper}>
        <Row>
            <Col xs={12} md={8}>
                <strong>Keyboard commands</strong>:
                <ul>
                    <li>
                        <i className={`${styles.keyboardKey} fa fa-caret-square-o-left`} />&nbsp;
                        Prev presentation
                    </li>
                    <li>
                        <i className={`${styles.keyboardKey} fa fa-caret-square-o-right`} />&nbsp;
                        Next presentation
                    </li>
                    {canEdit &&
                    <>
                        <li><span className={`${styles.keyboardKey}`}>[Y]</span>es</li>
                        <li><span className={`${styles.keyboardKey}`}>[I]</span>nterested</li>
                        <li><span className={`${styles.keyboardKey}`}>[N]</span>o thanks</li>
                        <li><span className={`${styles.keyboardKey}`}>[Q]</span>Clear</li>
                    </>
                    }
                </ul>
            </Col>
            <Col xs={12} md={4}>
                <span className={styles.presentationIndex}>
                    Viewing:&nbsp;
                    <strong>{index + 1} / {total}</strong>
                </span>
            </Col>
        </Row>
    </div>
)
