import React from 'react';
import ReactTooltip from 'react-tooltip';

export const Maybe = () => (
	<i className="fa fa-eye" />
);

export const Selected = () => (
	<i className="fa fa-thumbs-up" />
);

export const Pass = () => (
	<i className="fa fa-thumbs-down" />
);

export const Clear = () => (
	<i className="fa fa-times" />
);

export const Help = ({
						 className='help-link-container',
						 tooltip = '',
						 id='help',
						 place='bottom',
						 type='light',
						 effect='solid'}) => {
	return (
	<a className={className} data-tip={tooltip} data-for={id}>
		<ReactTooltip
			id={id}
			place={place}
			type={type}
			effect={effect}
			multiline={true}
			clickable={true}
			border={true}
			getContent={(dataTip) =>
				<div className="tooltip-popover"
					dangerouslySetInnerHTML={{__html: dataTip}}
				/>
			}
		/>
		<i className="fa fa-question-circle" />
	</a>
)};

export const Comments = () => (
	<i className="fa fa-comment" />
);

export const Score = () => (
	<i className="fa fa-star" />
)

export const PopularityScore = () => (
	<i className="fa fa-trophy" />
)

export const Team = () => (
	<i className="fa fa-group" />
);
