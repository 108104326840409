/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import {LOGOUT_USER} from "openstack-uicore-foundation/lib/security/actions";
import {
    CHANGE_REQUEST_RESOLVED,
    RECEIVE_CHANGE_REQUESTS,
    REQUEST_CHANGE_REQUESTS
} from "../actions/change-requests-actions";
import {RECEIVE_SELECTION_PLAN, RECEIVE_SUMMIT} from "../actions/base-actions";

const DEFAULT_STATE = {
    changeRequests  : [],
    search          : '',
    order           : 'id',
    orderDir        : 0,
    page            : 1,
    lastPage        : 1,
    perPage         : 10,
    totalRequests   : 0
};

const changeRequestsReducer = (state = DEFAULT_STATE, action) => {
    const { type, payload } = action;

    switch(type){
        case RECEIVE_SUMMIT:
        case RECEIVE_SELECTION_PLAN:
        case LOGOUT_USER:
            return DEFAULT_STATE;
        case REQUEST_CHANGE_REQUESTS: {
            const {search, page, perPage, order, orderDir} = payload;
            return {...state, search, page, perPage, order, orderDir};
        }
        case RECEIVE_CHANGE_REQUESTS: {
            const {data, total, last_page} = payload.response;

            const changeRequests = data.map(r => {

                return {
                    ...r,
                    presentation_title: `${r.presentation.id} - ${r.presentation.title}`,
                    new_category_name: r.new_category.name,
                    old_category_name: r.old_category.name,
                    requester_fullname: `${r.requester.first_name} ${r.requester.last_name}`,
                    canChange: r.presentation.likers_count === 0 && r.presentation.selectors_count === 0,
                };

            });

            return {...state, changeRequests, totalRequests: total, lastPage: last_page };
        }
        case CHANGE_REQUEST_RESOLVED: {
            const request = payload.response;
            const changeRequests = state.changeRequests.map(cr => {
                if (cr.id === request.id) {
                    return {
                        ...cr,
                        status: request.status,
                        aprover: request.aprover,
                        reason: request.reason
                    };
                }
                return cr;
            });

            return { ...state, changeRequests };
        }
        default:
            return state;
    }
};

export default changeRequestsReducer
