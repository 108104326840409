import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import {Modal} from "react-bootstrap";

const LockScheduleButton = ({onSubmit, locked, disabled, track = null}) => {
    const note = useRef('');
    const [show, setShow] = useState(false);

    const onSubmitClick = () => {
        const noteValue = note.current.value;
        setShow(false);
        onSubmit(noteValue);
    };

    const onClick = (ev) => {
        ev.preventDefault();
        setShow(true);
    };

    return (
        <>
            <button className={`btn ${locked ? 'btn-danger' : 'btn-default'}`} onClick={onClick} disabled={disabled || locked}>
                {locked ?  'Locked' : 'Lock for Review'}
            </button>
            <Modal show={show} onHide={() => setShow(false)} dialogClassName={styles.modalWrapper}>
                <Modal.Header closeButton>
                    <Modal.Title>Lock & Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        By clicking "Lock", the proposed schedule for track <b>{track?.name}</b> will be locked and sent for review to an admin.
                        Please note that after this action <b>no track-chair will be able to make changes to the proposed schedule</b>{' '}
                        until an admin unlocks it.
                    </p>
                    <textarea ref={note} placeholder="Note for admin (optional)" />
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger" onClick={() => onSubmitClick()}>
                        Lock
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


LockScheduleButton.propTypes = {
    track: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    locked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default LockScheduleButton;