/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
import React, {useEffect, useState, useMemo} from 'react';
import {connect} from "react-redux";
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import TrackDropdown from "../components/TrackDropdown";
import {ScheduleBuilderView} from "openstack-uicore-foundation/lib/components";
import TeamList from "../components/ScheduleTeamList/List";
import {SummitEvent} from "openstack-uicore-foundation/lib/models";
import {Team} from "../components/Icons";
import SelectionStats from "../components/SelectionStats";
import LockScheduleButton from "../components/LockScheduleButton";

import {
  getSelectionLists,
  getTeamList,
  getTrackChairs,
  reorderSelections,
  saveLists,
} from "../actions/selection-actions";

import {
  getProposedSchedule,
  publishEvent,
  unpublishEvent,
  setScheduleFilter,
  getTrackAllowedLocations,
  lockSchedule
} from '../actions/schedule-actions'

import styles from "../styles/schedule-page.module.scss";



const SchedulePage = (props) => {
  const {summit, tracksForUser, teamList, events, selectedDay, selectedLocation, selectedTrackId, selectedSlotSize, trackAllowedLoc} = props;
  const [teamPresentations, setTeamPresentations] = useState([]);
  const track = tracksForUser.find(t => t.id === selectedTrackId);
  const eventsIds = events.map(ev => ev.id);
  const eventIdsStr = eventsIds.join();
  const trackLimit = track?.session_count + track?.alternate_count;
  const teamListLength = teamList?.selected_presentations?.length || 0;
  const canLock = selectedTrackId && selectedLocation && selectedDay;
  const filteredEvents = useMemo(() => {
    if (!selectedDay || !selectedLocation) return [];
    return events.filter(ev => ev.location?.id === selectedLocation.id && ev.day === selectedDay)
  }, [events, selectedDay, selectedLocation?.id]);
  
  useEffect(() => {
    if (!props.loading) {
      props.getProposedSchedule();
    }
  }, []);
  
  useEffect(() => {
    if (selectedTrackId) {
      props.getTeamList(selectedTrackId);
      props.getTrackAllowedLocations(selectedTrackId);
    }
  }, [selectedTrackId]);
  
  useEffect(() => {
    if (teamList?.selected_presentations) {
      const selectedPres = teamList.selected_presentations.map(sel => {
        return({...sel, presentation: {...sel.presentation, proposed: eventsIds.includes(sel.presentation.id)}});
      });
      setTeamPresentations(selectedPres);
    }
  }, [eventIdsStr, teamList.id])
  
  const setFilter = (state) => {
    props.setScheduleFilter(state);
  };
  
  const onPublish = (event, currentDay, startDateTime, newDuration = null) => {
    const eventModel = new SummitEvent({...event}, summit);
    const minutes = newDuration ? newDuration : eventModel.getMinutesDuration(selectedSlotSize);
    eventModel._event.duration = minutes * 60;

    const [eventStarDateTime, eventEndDateTime ] = eventModel.calculateNewDates(currentDay, startDateTime, selectedSlotSize);
  
    props.publishEvent(event, eventStarDateTime, eventEndDateTime);
  }
  
  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2>Proposed Schedule</h2>
        </div>
        <div className={styles.colsWrapper}>
          <div className={styles.leftCol}>
            <div className={styles.filterWrapper}>
              <TrackDropdown
                value={selectedTrackId}
                tracks={tracksForUser}
                onSelect={trackId => setFilter({selectedTrackId: trackId})}
                valueKey="id"
              />
              <LockScheduleButton
                track={track}
                onSubmit={props.lockSchedule} 
                locked={props.isLocked}
                disabled={!canLock}
              />
            </div>
            {track &&
              <div className={`${styles.list} ${styles.team}`}>
                <h3>
                  <Team /> Proposed Accepted ({teamListLength} / {trackLimit})
                </h3>
                <SelectionStats selections={teamList.selected_presentations} />
                <TeamList
                  selections={teamPresentations}
                  onReset={props.unpublishEvent}
                  track={track}
                  summit={summit}
                  locked={props.isLocked}
                />
              </div>
            }
          </div>
          <div className={styles.rightCol}>
            <ScheduleBuilderView
              summit={summit}
              scheduleEvents={filteredEvents}
              currentDay={selectedDay}
              currentVenue={selectedLocation}
              slotSize={selectedSlotSize}
              showPrint={true}
              trackSpaceTime={trackAllowedLoc}
              canDropEvent={() => true}
              onDayChanged={day => setFilter({selectedDay: day})}
              onVenueChanged={loc => setFilter({selectedLocation: loc})}
              onSlotSizeChange={slot => setFilter({selectedSlotSize: slot})}
              hideBulkSelect
              onScheduleEvent={onPublish}
              onUnPublishEvent={props.unpublishEvent}
            />
          
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

const mapStateToProps = ({baseState, selectionsState, loggedUserState, scheduleState}) => ({
  ...baseState,
  ...scheduleState,
  teamList: selectionsState.teamList,
  loggedUser: loggedUserState.member
});

export default connect(
  mapStateToProps,
  {
    getTrackChairs,
    getTeamList,
    getSelectionLists,
    reorderSelections,
    saveLists,
    getProposedSchedule,
    setScheduleFilter,
    publishEvent,
    unpublishEvent,
    getTrackAllowedLocations,
    lockSchedule
  }
)(SchedulePage);