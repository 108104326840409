/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import React, {useEffect} from 'react'
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {getAllFromSummit, clearSelectionPlan, setLoggedTrackChair} from '../actions/base-actions';
import SelectionPlanSelectionPage from '../pages/selection-plan-selection-page';
import UnauthorizedPage from "../pages/unauthorized-page";
import {isAdmin} from "../utils/methods";

import SelectionPlanLayout from './selection-plan-layout';

const SummitLayout = ({member, summit, loggedTrackChair, match, clearSelectionPlan, getAllFromSummit, setLoggedTrackChair}) => {
    const paramSlug = match?.params?.summit_slug;
    const summitSlug = summit?.slug;
    const memberAdmin = isAdmin(member);

    useEffect(() => {
        clearSelectionPlan();
        if (!summit || summitSlug !== paramSlug) {
            getAllFromSummit(paramSlug);
        }
    }, [paramSlug, summitSlug]);

    useEffect(() => {
        if (member && summit) {
            setLoggedTrackChair()
        }
    }, [member?.id, summitSlug]);


    if (!member || !summit || summitSlug !== paramSlug) {
        return null;
    }

    if (!loggedTrackChair && !memberAdmin) {
        return <UnauthorizedPage />
    }

    return(
        <div className="primary-layout container-fluid">
            <main id="page-wrap">
                <Switch>
                    <Route strict exact path={match.url} component={SelectionPlanSelectionPage} />
                    <Route path={`${match.url}/:selection_plan_id(\\d+)`} component={SelectionPlanLayout} />
                    <Route render={props => (<Redirect to={`/app/${summit.slug}`}/>)}/>
                </Switch>
            </main>
        </div>
    );

}

const mapStateToProps = ({ loggedUserState, baseState }) => ({
    member: loggedUserState.member,
    summit: baseState.summit,
    loggedTrackChair: baseState.loggedTrackChair
});

export default connect(
    mapStateToProps,
    {
        getAllFromSummit,
        clearSelectionPlan,
        setLoggedTrackChair
    }
)(SummitLayout)


