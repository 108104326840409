import React from 'react';
import {RawHTML} from 'openstack-uicore-foundation/lib/components';
import ReactStars from 'react-stars';
import EmailLink from "../../../EmailLink";

import styles from './index.module.scss';
import {formatPersonEmailRFC, formatPersonFullName} from "../../../../utils/methods";
import Copy2ClipBoardButton from "../../../Copy2ClipBoardButton";

export default ({
                    speaker,
                    presentationId,
                    canEmail,
                    onPostNote,
                }) => {
    const {first_name, last_name, title, twitter, bio, pic, avg_rate_width, is_moderator, email, company} = speaker;
    let fullName = `${first_name} ${last_name}`;
    if (company)
        fullName += ` - ${company}`;

    return (
        <div className={styles.wrapper}>
            <div className={styles.profile}>
                <div className={styles.pic} style={{backgroundImage: `url(${pic})`}}/>
                <div>{title}</div>
                <h3>
                    <strong>{fullName}</strong>
                </h3>
                {!is_moderator &&
                <div className={styles.rating}>
                    <ReactStars value={avg_rate_width} edit={false} color1="lightgray"/>
                </div>
                }

                {twitter &&
                <p>
                    <i className="fa fa-twitter"/> {decodeURIComponent(twitter)}
                </p>
                }
                {email && email !== '' && canEmail ?
                    <>
                        <span title={formatPersonEmailRFC(speaker)}>
                            <i className="fa fa-envelope"/>&nbsp;{formatPersonFullName(speaker)}
                        </span>&nbsp;<Copy2ClipBoardButton
                            presentationId={presentationId}
                            onPostNote={onPostNote}
                        text2Copy={formatPersonEmailRFC(speaker)}/>
                    </> :
                    <>
                    <span title={formatPersonEmailRFC(speaker)}>
                        {email ?  <>
                                    <i className="fa fa-envelope"/>&nbsp;{formatPersonFullName(speaker)}
                                    &nbsp;<Copy2ClipBoardButton
                                    justCopy={true}
                                    text2Copy={formatPersonEmailRFC(speaker)}/>
                            </>
                            : 'N/A'}
                    </span>
                    </>
                }
            </div>
            <div className={styles.bio}>
                {bio && <RawHTML>{bio}</RawHTML>}
            </div>
        </div>
    );
}