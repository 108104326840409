/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import {
    getRequest,
    putRequest,
    postRequest,
    deleteRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler
} from "openstack-uicore-foundation/lib/utils/actions";
import {getAccessTokenSafely} from "../utils/methods";
import moment from 'moment-timezone';

export const RECEIVE_TRACK_ALLOWED_LOC = 'RECEIVE_TRACK_ALLOWED_LOC';
export const REQUEST_PROPOSED_SCHED = 'REQUEST_PROPOSED_SCHED';
export const RECEIVE_PROPOSED_SCHED = 'RECEIVE_PROPOSED_SCHED';
export const REQUEST_SHOW_ALWAYS_EVENTS = 'REQUEST_SHOW_ALWAYS_EVENTS';
export const RECEIVE_SHOW_ALWAYS_EVENTS = 'RECEIVE_SHOW_ALWAYS_EVENTS';
export const REQUEST_PROPOSED_SCHED_LOCK = 'REQUEST_PROPOSED_SCHED_LOCK';
export const RECEIVE_PROPOSED_SCHED_LOCK = 'RECEIVE_PROPOSED_SCHED_LOCK';
export const UPDATE_SCHED_FILTER = 'UPDATE_SCHED_FILTER';
export const SCHED_EVENT_PUBLISHED = 'SCHED_EVENT_PUBLISHED';
export const SCHED_EVENT_UNPUBLISHED = 'SCHED_EVENT_UNPUBLISHED';
export const LOCK_PROPOSED_SCHED = 'LOCK_PROPOSED_SCHED';

export const getTrackAllowedLocations = (trackId) => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit } = baseState;
    const accessToken = await getAccessTokenSafely();
    
    dispatch(startLoading());
    
    const params = {
        access_token : accessToken,
        expand: 'allowed_timeframes',
        per_page: 100
    };
    
    return getRequest(
      null,
      createAction(RECEIVE_TRACK_ALLOWED_LOC),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tracks/${trackId}/proposed-schedule-allowed-locations`,
      authErrorHandler,
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
}

export const getProposedSchedule = (page = 1) => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit } = baseState;
    const accessToken = await getAccessTokenSafely();
    
    const params = {
        expand: 'location,summit_event,summit_event.speakers,summit_event.moderator,summit_event.tags,summit_event.track,summit_event.location',
        page,
        per_page: 100,
        access_token: accessToken,
    };
    
    dispatch(startLoading());
    
    return getRequest(
      createAction(REQUEST_PROPOSED_SCHED),
      createAction(RECEIVE_PROPOSED_SCHED),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/proposed-schedules/track-chairs/presentations`,
      authErrorHandler,
      {page}
    )(params)(dispatch).then(({response}) => {
        if (page < response.last_page) {
            return getProposedSchedule(page + 1);
        } else {
            dispatch(getShowAlwaysEvents());
            dispatch(getProposedScheduleLocks());
            return Promise.resolve();
        }
    });
}

export const getShowAlwaysEvents = () => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit } = baseState;
    const accessToken = await getAccessTokenSafely();
    
    const params = {
        page: 1,
        per_page: 100,
        access_token: accessToken,
        expand: 'location,speakers,moderator,tags,track',
        'filter[]': [
            `type_show_always_on_schedule==true`
        ]
    };
    
    dispatch(startLoading());
    
    return getRequest(
      createAction(REQUEST_SHOW_ALWAYS_EVENTS),
      createAction(RECEIVE_SHOW_ALWAYS_EVENTS),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/events/published`,
      authErrorHandler,
    )(params)(dispatch)
      .then(() =>
        dispatch(stopLoading())
      );
}

export const getProposedScheduleLocks = () => async (dispatch, getState) => {
    const { baseState, scheduleState } = getState();
    const { summit } = baseState;
    const { selectedTrackId } = scheduleState;
    const accessToken = await getAccessTokenSafely();
    
    const params = {
        expand: '',
        page: 1,
        per_page: 100,
        access_token: accessToken,
        'filter[]': [`track_id==${selectedTrackId}`]
    };
    
    if (selectedTrackId) {
        dispatch(startLoading());
    
        return getRequest(
          createAction(REQUEST_PROPOSED_SCHED_LOCK),
          createAction(RECEIVE_PROPOSED_SCHED_LOCK),
          `${window.API_BASE_URL}/api/v1/summits/${summit.id}/proposed-schedules/track-chairs/locks`,
          authErrorHandler,
        )(params)(dispatch).then(() => {
            dispatch(stopLoading());
        });
    }
}

export const setScheduleFilter = (state) => (dispatch) => {
    dispatch(createAction(UPDATE_SCHED_FILTER)(state));
}

export const publishEvent = (event, startDate, endDate) => async (dispatch, getState) => {
    const { baseState, scheduleState } = getState();
    const { summit } = baseState;
    const {selectedLocation} = scheduleState;
    const accessToken = await getAccessTokenSafely();
    
    const startEpoch = startDate.valueOf() / 1000;
    const endEpoch = endDate.valueOf() / 1000;
    
    const params = {
        access_token : accessToken,
        expand: 'location,summit_event,summit_event.speakers,summit_event.moderator,summit_event.tags,summit_event.track,summit_event.location'
    };
    
    dispatch(startLoading());
    
    putRequest(
      null,
      createAction(SCHED_EVENT_PUBLISHED),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/proposed-schedules/track-chairs/presentations/${event.id}/propose`,
      {start_date: startEpoch, end_date: endEpoch, location_id: selectedLocation.id},
      authErrorHandler
    )(params)(dispatch).finally(() => {
        dispatch(stopLoading());
    });
};

export const unpublishEvent = (event) => async (dispatch, getState) => {
    const { baseState } = getState();
    const { summit } = baseState;
    const accessToken = await getAccessTokenSafely();
    
    dispatch(startLoading());
    
    const params = {
        access_token : accessToken,
    };
    
    return deleteRequest(
      null,
      createAction(SCHED_EVENT_UNPUBLISHED)({eventId: event.id}),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/proposed-schedules/track-chairs/presentations/${event.id}/propose`,
      authErrorHandler,
    )(params)(dispatch).finally(() => {
        dispatch(stopLoading());
    });
};

export const lockSchedule = (message) => async (dispatch, getState) => {
    const { baseState, scheduleState } = getState();
    const { summit } = baseState;
    const {selectedTrackId} = scheduleState;
    
    const accessToken = await getAccessTokenSafely();
    
    dispatch(startLoading());
    
    const params = {
        access_token : accessToken,
    };
    
    return postRequest(
      null,
      createAction(LOCK_PROPOSED_SCHED)({}),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/proposed-schedules/track-chairs/tracks/${selectedTrackId}/lock`,
      {message},
      authErrorHandler,
    )(params)(dispatch).finally(() => {
        dispatch(stopLoading());
    });
};