import React from 'react';
import PropTypes from 'prop-types';
import SpeakerCard from './SpeakerCard';

const PresentationSpeakers = ({speakers, presentationId, canEmail, onPostNote}) => {

	return (
		<div>
			{speakers.length > 0 ? (
				<>
					{speakers.map(s =>
						<SpeakerCard
							key={s.id}
							speaker={s}
							presentationId={presentationId}
							canEmail={canEmail}
							onPostNote={onPostNote}
						/>
					)}
				</>
			) : (
				<span>N/A</span>
			)}
		</div>
	)
}

PresentationSpeakers.defaultProps = {
	perRow: 1
};

PresentationSpeakers.propTypes = {
	speakers: PropTypes.array.isRequired
};

export default PresentationSpeakers;