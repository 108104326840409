import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import Card from '../../SelectionList/Card';

const ScheduleTeamCard = (props) => {
    const {id, presentation, track, locked} = props;
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: 'ScheduleEvent',
        item: () => ({ ...presentation }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: !presentation.proposed && !locked && !!track
    }, [presentation.proposed]);
    
    const opacity = isDragging ? 0 : 1;

    if (!presentation) return null;

    drag(ref);
    
    return (
        <div ref={ref} style={{ opacity, marginBottom: 10 }}>
            <Card {...props} canDrag={!presentation.proposed && !!track && !locked} />
        </div>
    );
}

ScheduleTeamCard.propTypes = {
    id: PropTypes.any.isRequired,
    presentation: PropTypes.any.isRequired,
};

export default ScheduleTeamCard;