/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

 import { START_LOADING, RESET_LOADING, STOP_LOADING } from "openstack-uicore-foundation/lib/utils/actions";
import { LOGOUT_USER } from "openstack-uicore-foundation/lib/security/actions";

import {
     ERROR_RECEIVE_SUMMIT,
     REQUEST_SELECTION_PLAN,
     RECEIVE_SELECTION_PLAN,
     RECEIVE_SUMMIT,
     RECEIVE_MARKETING_SETTINGS,
     RECEIVE_AVAILABLE_SUMMITS,
     SELECTION_CLOSED,
     SET_LOGGED_TRACK_CHAIR,
     RECEIVE_ACTION_TYPES, 
     REQUEST_SUMMIT,
     CLEAR_SELECTION_PLAN,
     CLEAR_SUMMIT,
 } from "../actions/base-actions";

 import {getAvailableTracksFromSelectionplan, getTrackForuser, nowBetween} from "../utils/methods";

 import store from "../store";
  
 const DEFAULT_STATE = {
     loading: 0,
     marketingSettings: null,
     summits: [],
     summit: null,
     selectionPlan: null,
     selectionOpen: false,
     allTracks: [],
     tracksForUser: [],
     loggedTrackChair: null,
     actionTypes: [],
     baseLoaded: false,
 };
 
 const baseReducer = (state = DEFAULT_STATE, action) => {
     const { type, payload } = action;

     switch(type){
         case LOGOUT_USER:
         case CLEAR_SUMMIT:
             return DEFAULT_STATE;
         case START_LOADING: {
             // const loadingCount = state.loading + 1;
             return {...state, loading: 1};
         }
         case STOP_LOADING: {
             // const loadingCount = state.loading <= 1 ? 0 : (state.loading - 1);
             return {...state, loading: 0};
         }
         case RESET_LOADING: {
             return {...state, loading: 0};
         }
         case RECEIVE_AVAILABLE_SUMMITS: {
             const {data} = payload.response;
             return {...DEFAULT_STATE, summits: data, loading: state.loading};
         }
         // summit / selection plan
         case REQUEST_SUMMIT: {
             return {...DEFAULT_STATE, summits: state.summits, loading: state.loading};
         }
         case RECEIVE_SUMMIT: {
             let entity = {...payload.response};
             return {...state, summit: entity, marketingSettings: null, selectionPlan: null, baseLoaded: false};
         }
         case ERROR_RECEIVE_SUMMIT:
             return {...DEFAULT_STATE, summits: state.summits, loading: state.loading};
         case CLEAR_SELECTION_PLAN:
             return {...state, selectionPlan: null, selectionOpen: false, baseLoaded: false};
         case REQUEST_SELECTION_PLAN: {
             return {...state, selectionPlan: null, selectionOpen: false, allTracks: [], tracksForUser: [], baseLoaded: false};
         }
         case RECEIVE_SELECTION_PLAN: {
            const entity = {...payload.response};
            const {member} = store.getState().loggedUserState;
            const allTracks = getAvailableTracksFromSelectionplan(entity);
            const tracksForUser = getTrackForuser(entity, member, state.loggedTrackChair)
            const selectionOpen = nowBetween(entity.selection_begin_date, entity.selection_end_date);
            
            return {...state, selectionPlan: entity, selectionOpen, allTracks, tracksForUser, baseLoaded: true};

         }
         case RECEIVE_MARKETING_SETTINGS: {
             const {data} = payload.response;
             // set color vars
             if (typeof document !== 'undefined') {
                 data.forEach(setting => {
                     if (getComputedStyle(document.documentElement).getPropertyValue(`--${setting.key}`)) {
                         document.documentElement.style.setProperty(`--${setting.key}`, setting.value);
                         document.documentElement.style.setProperty(`--${setting.key}50`, `${setting.value}50`);
                     }
                 });
             }
             return {...state, marketingSettings: data};
         }
         case RECEIVE_ACTION_TYPES: {
             const {data} = payload.response;
             return {...state, actionTypes: data};
         }
         case SELECTION_CLOSED: {
             return {...state, selectionPlan: null, selectionOpen: false, baseLoaded: true}
         }
         case SET_LOGGED_TRACK_CHAIR: {
             const {trackChair} = payload;
             return {...state, loggedTrackChair: trackChair};
         }
         default:
             return state;
     }
 }
 
 export default baseReducer
 