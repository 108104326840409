/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import history from "../history";
import {FreeTextSearch} from 'openstack-uicore-foundation/lib/components';
import PresentationList from '../components/PresentationList';
import TrackDropdown from '../components/TrackDropdown';
import PresentationFilter from '../components/PresentationFilter';
import PresentationDetail from "../components/PresentationDetail";
import TagCloudDropdown from '../components/TagCloudDropdown';
import {
  getTrackPresentations,
  getPresentation,
  exportPresentations,
  pullMorePresentations,
  removeCurrentPresentation
} from "../actions/presentation-actions";
import TrackChair from "../model/TrackChair";

import styles from '../styles/browse-page.module.scss';
import Swal from "sweetalert2";

const BrowsePage = ({
                      selectionPlan,
                      summit,
                      presentation,
                      track,
                      filter,
                      allTracks,
                      match,
                      presentationsLoaded,
                      presentations,
                      ...props
                    }) => {
  
  const refIndex = useRef(presentation && presentations.findIndex(p => p.id === presentation.id));

  const keyListener = (e) => {
    const adder = e.keyCode === 37 ? -1 : (e.keyCode === 39 ? 1 : 0);
    const {tagName} = e.target;
  
    if (tagName === 'TEXTAREA' || tagName === 'INPUT') return;
    if (!adder) return;
  
    let index = refIndex.current;
    index += adder;
    const newPresentation = presentations[index];
  
    if (newPresentation) {
      refIndex.current = index;
      history.push(`${newPresentation.id}`);
    }
  };

  useEffect(() => {
    if (presentationsLoaded) {
      document.addEventListener('keyup', keyListener);
    }
    return () => {
      document.removeEventListener('keyup', keyListener);
    }
  }, [presentationsLoaded]);

  // update index on presentation change 
  useEffect(() => {
    const newIndex = presentations.findIndex(p => p.id === presentation?.id)
    refIndex.current = newIndex ? newIndex : 0;
  }, [presentation])

  // select and pull track
  useEffect(() => {
    if (allTracks.length > 0) {
      const {track_slug} = match.params;

      if (!track_slug) {
        selectTrack(allTracks[0].slug);
      } else if (!track || track_slug !== track.slug || !presentationsLoaded) {
        props.getTrackPresentations(1, track_slug);
      }
    }
  }, [match.params.track_slug, allTracks.length]);

  // select and pull presentation
  useEffect(() => {
    const {track_slug} = match.params;
    const {presentation_id} = match.params;
    const presentationId = parseInt(presentation_id);
    const presentationsIds = presentations.map(p => p.id);

    if (presentations.length > 0 && track_slug === track.slug && presentationsLoaded) {
      if (!presentation_id || !presentationsIds.includes(presentationId)) {
        selectPresentation(presentations[0]);
      } else {
        props.getPresentation(presentationId);
      }
    }
  }, [match.params.presentation_id, presentations.length, presentationsLoaded]);

  // deselect presentation if it doesn't belong to track
  useEffect(() => {
    if (presentation && track && presentation.track_id !== track.id) {
      props.removeCurrentPresentation().then(() => {
        Swal.fire("Warning", `Presentation does not belong to track ${track.name}`, "warning");
        history.push(`/app/${summit.slug}/${selectionPlan.id}/browse/${track.slug}`);
      })
    }
  }, [presentation?.id, track?.id]);

  const selectTrack = trackSlug => {
    history.push(`/app/${summit.slug}/${selectionPlan.id}/browse/${trackSlug}`);
  };

  const selectPresentation = presentation => {
    history.push(`/app/${summit.slug}/${selectionPlan.id}/browse/${track.slug}/${presentation.id}`);
  };

  const searchPresentation = search => {
    const {trackSlug, status, tag} = filter;
    props.getTrackPresentations(1, trackSlug, status, search, tag);
  };

  const filterPresentations = key => {
    const {trackSlug, search, tag} = filter;
    props.getTrackPresentations(1, trackSlug, key, search, tag);
  };

  const filterPresentationsTag = tag => {
    const {trackSlug, status, search} = filter;
    props.getTrackPresentations(1, trackSlug, status, search, tag);
  };

  const {status, tag, search, trackSlug} = filter;
  const trackChairUser = props.loggedTrackChair ? new TrackChair(props.loggedTrackChair) : null;
  const hasMorePresentations = props.total > presentations.length;

  if (!track) return null;

  return (
    <div className={`${styles.wrapper} row`}>
      <div className={`${styles.leftCol} col-md-4`}>
        <div className={styles.actions}>
          <div className={styles.searchWrapper}>
            <FreeTextSearch
              value={search}
              placeholder="Search presentations"
              onSearch={searchPresentation}
            />
          </div>
          <div className={styles.filters}>
            <div className={styles.filterButton}>
              <PresentationFilter onSelect={filterPresentations} actionTypes={props.actionTypes} activeFilter={status}/>
            </div>
            <div className={styles.filterButton}>
              <TagCloudDropdown
                value={tag}
                tags={props.tags}
                onTagClick={filterPresentationsTag}
                onClearTag={() => filterPresentationsTag(null)}
              />
            </div>
            <div className={styles.trackFilter}>
              <TrackDropdown onSelect={selectTrack} value={trackSlug} tracks={allTracks}/>
            </div>
            <div className={styles.export}>
              <button className="btn btn-default" onClick={() => props.exportPresentations()}>
                <span><i className="fa fa-download"/>&nbsp;Export</span>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.list}>
          <PresentationList
            presentations={presentations}
            presentationId={presentation ? presentation.id : null}
            hasMore={presentations.length < props.total}
            track={track}
            search={search}
            onLoadMore={props.pullMorePresentations}
            onSelect={selectPresentation}
          />
          {hasMorePresentations &&
          <div className={styles.loadMore}>
            <button onClick={props.pullMorePresentations}>Load more</button>
          </div>
          }
        </div>
      </div>
      <div className={`${styles.rightCol} col-md-8`}>
        <PresentationDetail
          presentation={presentation}
          trackChairUser={trackChairUser}
          onTagClick={filterPresentationsTag}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({baseState, presentationsState, loggedUserState}) => ({
  loggedUser: loggedUserState.member,
  ...baseState,
  ...presentationsState
});

export default connect(
  mapStateToProps,
  {
    getTrackPresentations,
    pullMorePresentations,
    getPresentation,
    exportPresentations,
    removeCurrentPresentation,
  }
)(BrowsePage);