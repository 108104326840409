import React from 'react';

export default class ActionsTableCell extends React.Component {

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
        this.shouldDisplayAction = this.shouldDisplayAction.bind(this);

    }

    shouldDisplayAction(action) {
        let {id} = this.props;

        if (!action.hasOwnProperty('display')) {
            return true;
        } else {
            return action.display(id);
        }
    }

    handleAction(action, id, ev) {
        ev.stopPropagation();
        ev.preventDefault();

        action(id);
    }

    render() {
        let {actions, id} = this.props;
        return (
            <td className="actions" key="actions">
                <div className="resolution-buttons btn-group">
                    <button className="btn btn-xs btn-success" onClick={() => actions.onApprove(id)}>Approve</button>
                    <button className="btn btn-xs btn-danger" onClick={() => actions.onReject(id)}>Reject</button>
                </div>
            </td>
        );
    }
};
