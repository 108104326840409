import React from 'react';
import styles from "./index.module.scss";

export default ({extraQuestions}) => {

    const multiValuesQuestionsTypes = ['CheckBoxList', 'ComboBox', 'RadioButtonList'];

    return (<ul>
        {
            extraQuestions.sort((a, b) => a.question.order - b.question.order)
                .map((q) => {
                    // split repetitive groups
                    let rawVal = q.value.split(",");

                    // map value

                    let value = q.question.hasOwnProperty("values") ?
                        // join char ; for multi value questions
                        q.question.values.filter((v) => rawVal.includes(v.id.toString())).map((v) => v.label).join("; ") :
                        q.value;

                    // check for multivalue not selected
                    if (multiValuesQuestionsTypes.includes(q.question.type)) {
                        value = value ? value : 'None selected';
                    } else {
                        if (value == 'true') {
                            value = 'Yes';
                        } else if (value == 'false') {
                            value = 'No';
                        } else if (!value) {
                            value = 'TBD';
                        }
                    }

                    return (<li className={styles.question} key={q.id}>
                        <label dangerouslySetInnerHTML={{__html: q.question.label}}/>
                        <span dangerouslySetInnerHTML={{__html: ' ' + value}}/>
                    </li>)
                })
        }
    </ul>)
}