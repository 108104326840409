import React from 'react';

import styles from './index.module.scss';

export default ({ status }) => {
	let text = '';
	let className = '';

	switch (status) {
		case 'team':
			text = 'TEAM SELECTION';
			className = styles.primary;
			break;
		case 'selected':
			text = 'Selected';
			className = styles.success;
			break;
		case 'maybe':
			text = 'Interested';
			className = styles.warning;
			break;
		case 'pass':
			text = 'Rejected';
			className = styles.danger;
			break;
	}

	return (
		<div className={`${styles.ribbon} ${className}`}>
			<span>{text}</span>
		</div>
	);
};