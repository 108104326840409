import React from 'react';
import styles from './index.module.scss';

export default class CommentForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			value: '',
            is_public: false
		};

		this.updateValue = this.updateValue.bind(this);
		this.changePublic = this.changePublic.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	updateValue(e) {
		this.setState({
			value: e.target.value
		});
	}

    changePublic(e) {
        this.setState({
            is_public: e.target.checked
        });
    }

	handleSubmit(e) {
		const {value, is_public} = this.state;
		const {presentationId, onComment} = this.props;

		e.preventDefault();

		if(!value) return;

		onComment(presentationId, value, false);

		this.setState({ value: '', is_public: false});
	}

	render() {		
		return (
	        <div className={styles.wrapper}>
	           <form role="form" onSubmit={this.handleSubmit}>
				   {/*<div className={styles.checkboxWrapper}>
					   <div className="form-check checkbox">
						   <input type="checkbox" id="is_public" checked={this.state.is_public} onChange={this.changePublic} />
						   <label htmlFor="is_public">
							   Message the Submitter (not speakers) through FNspeaker.
						   </label>
					   </div>
				   </div>*/}
	              <div className="form-group">
	                 <textarea value={this.state.value} onChange={this.updateValue} className="form-control" placeholder="Write a note..." />
	              </div>
	              <div className="text-right">
	                 <button type="submit" className="btn btn-sm btn-primary m-t-n-xs"><strong>Post note</strong></button>
	              </div>
	           </form>
	        </div>
	    );
	}
}