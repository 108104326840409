import React from 'react';
import styles from './index.module.scss';

export default class PresentationTags extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let {tags} = this.props;

		return (
			<div className={styles.wrapper}>
				{tags.map(t =>
					{
						if (t.Tag !== '') {
							return (
								<button
									key={'tag_'+t.tag}
									className="btn btn-xs btn-default"
									onClick={() => this.props.onTagClick(t.tag)}
								>
									{t.tag}
								</button>
							);
						}
					}
				)}
			</div>
		);		
	}	
}