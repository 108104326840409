import React from 'react';
import {Score, Selected, Maybe, Pass} from '../Icons';

import styles from './index.module.scss';

export default ({
	presentation
}) => {
	let icons = {
		'selectors_count': Selected,
		'likers_count': Maybe,
		'passers_count': Pass,
		'track_chair_avg_score': Score
	};

	let innerContent = Object.keys(icons).map(k => {
		if(presentation.hasOwnProperty(k)) {
			let Icon = icons[k];

			if (presentation[k] === 0) return null;

			return (
				<span key={`stat-${k}`} className={styles[k]}>
					<Icon /> {presentation[k]}
				</span>
			);
		}
	});

	innerContent.push(
		<span key='comments' className={styles.comment}>
			<i className="fa fa-comment" /> {presentation.comments_count}
		</span>
	);

	if(presentation.is_group_selected) {
		innerContent.push(
			<span key='team' className={styles.team}>
				<i className="fa fa-users" />
			</span>
		);
	}

    if(!!presentation.moved_to_category) {
        innerContent.push(
            <span key='moved' className={styles.moved}>
                <i className="fa fa-exchange" />
            </span>
        );
    }

	return (
		<span className={styles.wrapper}>{innerContent}</span>
	);
}