import React from 'react'
import ReactStars from 'react-stars'
import styles from './index.module.scss';
import {Help} from "../Icons";
import PropTypes from 'prop-types';

const PresentationRating = ({presentation, selectionPlan, onChangeRating, onClearRating}) => {
  if (!selectionPlan?.track_chair_rating_types?.length) return null;

  const getTitle = (rt) => {
    let res = ''
    rt.score_types.map((st) => {
      if (res !== '') res = res + '<br>';
      res = res + `${st.score}. <b>${st.name.trim()}</b> <p>${st.description.trim()}</p>`;
    })
    return res;
  };

  const getScore = (ratingType) => {
    const {track_chair_scores} = presentation;
    const score = track_chair_scores?.find(s => s.type.type_id === ratingType.id);
    return score || null;
  };

  const getAvgScore = (ratingType) => {
    const {track_chair_scores_avg} = presentation;
    const avgScore = track_chair_scores_avg?.find(s => parseInt(s.ranking_type_id) === ratingType.id)?.avg_score.slice(0, 3);
    return avgScore || null;
  };


  return (
    <>
      <ul className={styles.presentation_rating_container}>
        {selectionPlan.track_chair_rating_types.filter(rt => rt.score_types.length > 0).map(rt => {
          const score = getScore(rt);
          const avgScore = getAvgScore(rt);
          return (
            <li key={rt.id}>
              <span className={styles.presentation_rating_name}>
                  <Help id={`${rt.id}_help`} tooltip={getTitle(rt)}/>&nbsp;{rt.name}:&nbsp;
              </span>
              <ReactStars
                value={score?.type?.score || 0}
                className={styles.presentation_rating_stars}
                id={rt.id}
                name={rt.name}
                count={rt.score_types.length}
                size={24}
                color1="lightgray"
                color2={'#ffd700'}
                half={false}
                onChange={newRating => {
                  onChangeRating(presentation, rt.score_types[newRating - 1])
                }}
              />
              <span className={score ? styles.averageScore : styles.noScore}>{avgScore}</span>
              <div className={styles.clearBox}>
                {score && <span onClick={() => onClearRating(presentation.id, score)}><i className="fa fa-times" /> Clear</span>}
              </div>
            </li>
          )
        })}
      </ul>
      <span className={styles.avgScoreText}>AVG. SCORE</span>
    </>
  );
}

export default PresentationRating;

PresentationRating.propTypes = {
  presentation: PropTypes.object,
  selectionPlan: PropTypes.object,
  onChangeRating: PropTypes.func,
};

PresentationRating.defaultProps = {
  onChangeRating: (presentation, scoreType) => {
    console.log(`presentation ${presentation.id} score type ${scoreType.id} score ${scoreType.score} `);
  }
};

