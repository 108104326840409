import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { RawHTML } from 'openstack-uicore-foundation/lib/components';

import {ListGroup, ListGroupItem} from "react-bootstrap";
import PresentationMetrics from '../PresentationMetrics';

import styles from './index.module.scss';

const PresentationList = ({
    presentations,
	presentationId,
	onSelect,
    hasMore,
	onLoadMore
}) => {

	const handleScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom && hasMore) {
			onLoadMore();
		}
	};

	if (presentations.length === 0) {
		return (
			<div className={styles.noPresentations}>No presentations for this track.</div>
		)
	}

	return (
		<ListGroup className={styles.wrapper} onScroll={handleScroll}>
			{presentations.map(presentation => {
				const wrapperClass = `${styles.presentationWrapper} feed-item ${presentation.viewed ? '' : 'not-viewed'}`;
				const active = presentationId === presentation.id;
				return (
					<ListGroupItem
						className={wrapperClass}
						key={`pres-${presentation.id}`}
						onClick={() => onSelect(presentation)}
						active={active}
					>
						<strong>
							{`${presentation.id} - ${presentation.title}`}
						</strong>
						<div className={styles.description}>
							<RawHTML>{presentation.description}</RawHTML>
						</div>
						<PresentationMetrics presentation={presentation} />
						{typeof (presentation.rank) === "number" && presentation.rank !== 0 &&
						<span className={styles.rank}>
							{presentation.rank}
						</span>
						}
						{presentation.updated_by &&
						<span className={styles.lastUpdate}>
							{`Last updated by ${presentation.updated_by.first_name} ${presentation.updated_by.last_name}`}
						</span>
						}
					</ListGroupItem>
				);
			})}
		</ListGroup>
	);
};

PresentationList.propTypes = {
	presentations: PropTypes.array,
	presentationId: PropTypes.number,
	hasMore: PropTypes.bool,
	onLoadMore: PropTypes.func
};

PresentationList.defaultProps = {
	presentations: [],
	presentationId: 0
};

export default PresentationList;