import React from 'react';
import moment from "moment";
import T from 'i18n-react/dist/i18n-react'
import CommentForm from './CommentForm';
import {getInitials} from "../../../utils/methods";

import styles from './index.module.scss';

export default ({presentation, onComment, readOnly, someAllowDropBox}) => {
    const activity = presentation.comments;

    const itemClass = (c) => `${styles.item} ${c.is_public ? styles.public : ''}`;
    const itemIcon = (c) => c.is_activity ?
        <i className="fa fa-exclamation"/> : getInitials(`${c.creator?.first_name} ${c.creator?.last_name}`);

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <h3>Activity</h3>
            </div>
            <div className={styles.activityList}>
                {!activity || activity.length === 0 ? (
                    <div> No activity so far.</div>
                ) : (
                    <>
                        {activity.map(c => (
                            <div key={`comment-${c.id}`} className={itemClass(c)}>
                                <div className={styles.icon}>
                                    {itemIcon(c)}
                                </div>
                                <div className={styles.messageWrapper}>
                                    <div className={styles.messageHeader}>
                                <span className={styles.note}>
                                    {!c.is_activity &&
                                        <>
                                            <strong>{c.creator?.first_name} {c.creator?.last_name}</strong> posted a
                                            comment {c.is_public ? 'to the presenter' : ''}
                                        </>
                                    }
                                </span>
                                        <small className={styles.timestamp}>{moment.unix(c.created).fromNow()}</small>
                                    </div>
                                    <div className={styles.message}>
                                        {c.body}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
            {!readOnly &&
                <>
                    <p className={styles.dropboxCopy}>
                        {T.translate("presentation_activity.notes_info")}
                        {someAllowDropBox && T.translate("presentation_activity.some_allow_dropbox")}
                    </p>
                    <div className={styles.commentForm}>
                        <CommentForm presentationId={presentation.id} onComment={onComment}/>
                    </div>
                </>
            }
        </div>
    );
}
