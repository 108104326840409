import React, {useState} from 'react';
import PropTypes from 'prop-types';
import history from "../../../history";
import PresentationMetrics from "../../PresentationMetrics";

import styles from './index.module.scss';

const Card = (props) => {
    const [remove, setRemove] = useState(false);
    const [reset, setReset] = useState(false);
    const {summit, track, presentation, sortable, eventKey, canUp, canDown, showRank, rank, isAlternate, canDrag} = props;
    

    const handleUp = (e) => {
        e.preventDefault();
        props.onUp && props.onUp(eventKey);
    };

    const handleDown = (e) => {
        e.preventDefault();
        props.onDown && props.onDown(eventKey);
    };

    const preRemove = e => {
        e.preventDefault();
        setRemove(true);
    };
    
    const handleRemove = e => {
        e.preventDefault();
        props.onRemove(presentation.id);
        setRemove(false);
    };
    
    const preReset = e => {
        e.preventDefault();
        setReset(true);
    };
    
    const handleReset = e => {
        e.preventDefault();
        props.onReset(presentation);
        setReset(false);
    };

    const removeBlock = () => {
        if (!remove)
            return (
                <a href="#" className={styles.trash} onClick={preRemove}>
                    <i className="fa fa-trash"/>
                </a>
            );

        return (
            <div className={styles.confirm}>
                <a href="#" className={styles.yes} onClick={handleRemove}>
                    <i className="fa fa-check"/>
                </a>
                <span>Remove?</span>
                <a href="#" className={styles.no} onClick={() => {setRemove(false)}}>
                    <i className="fa fa-times"/>
                </a>
            </div>
        );
    };
    
    const resetBlock = () => {
        if (!reset)
            return (
              <a href="#" className={styles.refresh} onClick={preReset}>
                  <i className="fa fa-refresh"/>
              </a>
            );
        
        return (
          <div className={styles.confirm}>
              <a href="#" className={styles.yes} onClick={handleReset}>
                  <i className="fa fa-check"/>
              </a>
              <span>Reset?</span>
              <a href="#" className={styles.no} onClick={() => {setReset(false)}}>
                  <i className="fa fa-times"/>
              </a>
          </div>
        );
    };

    const goToPresentation = () => {
        history.push(`/app/${summit.slug}/${presentation.selection_plan_id}/browse/${track?.slug}/${presentation.id}`);
    };
    
    const displayRank = isAlternate? 'ALT' : `# ${rank}`;
    
    return (
      <div className={`${styles.wrapper} ${sortable ? styles.sortable : ''} ${canDrag ? '' : styles.disabled}`}>
          <div className={styles.header}>
              {showRank && <div className={styles.rank}>{displayRank}</div>}
              {sortable &&
                <div className={styles.actions}>
                    <div className={styles.actionWrapper}>
                        {canUp &&
                          <a className={styles.up} href="#" onClick={handleUp}>
                              <i className="fa fa-chevron-up"/>
                          </a>
                        }
                    </div>
                    <span>DRAG</span>
                    <div className={styles.actionWrapper}>
                        {canDown &&
                          <a className={styles.down} href="#" onClick={handleDown}>
                              <i className="fa fa-chevron-down" />
                          </a>
                        }
                    </div>
                </div>
              }
              <div className={styles.level}>{presentation.level}</div>
          </div>
          <div className={styles.body}>
              <div className={styles.title} onClick={() => goToPresentation()}>
                  {presentation.id} - {presentation.title}
              </div>
          </div>
          <div className={styles.footer}>
              <div className={styles.metrics}>
                  <PresentationMetrics presentation={presentation} />
              </div>
              {sortable &&
                <div className={styles.remove}>
                    {removeBlock()}
                </div>
              }
              {props.onReset && !canDrag && track &&
                <div className={styles.reset}>
                    {resetBlock()}
                </div>
              }
          </div>
      </div>
    );
}

Card.propTypes = {
    title: PropTypes.any,
    notes: PropTypes.any,
    rank: PropTypes.any,
    onUp: PropTypes.func,
    onDown: PropTypes.func,
    eventKey: PropTypes.any,
    canUp: PropTypes.bool,
    canDown: PropTypes.bool,
    canDrag: PropTypes.bool
};

Card.defaultProps = {
    canUp: true,
    canDown: true,
    showRank: true,
    canDrag: true
};

export default Card;