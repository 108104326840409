import React from 'react';
import {Table} from 'react-bootstrap';
import moment from 'moment';

export default ({ mediaUploads }) => {

	return (
		<Table hover>
			<thead>
				<tr>
					<th>Name</th>
					<th>File Name</th>
					<th>Last Edited</th>
				</tr>
			</thead>
			<tbody>
				{mediaUploads.map(mu => {
					const date = moment.unix(mu.last_edited).format('MMMM Do YYYY, h:mm:ss a');
					return (
						<tr key={mu.id} onClick={() => window.open(mu.private_url || mu.public_url, '_blank')} style={{cursor: 'pointer'}}>
							<td>{mu.name}</td>
							<td>{mu.filename}</td>
							<td>{date}</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
};