/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
import {epochToMomentTimeZone} from "openstack-uicore-foundation/lib/utils/methods";
import {LOGOUT_USER} from "openstack-uicore-foundation/lib/security/actions";
import { VALIDATE } from 'openstack-uicore-foundation/lib/utils/actions';

import {
    REQUEST_PROPOSED_SCHED,
    RECEIVE_PROPOSED_SCHED,
    RECEIVE_SHOW_ALWAYS_EVENTS,
    SCHED_EVENT_PUBLISHED,
    SCHED_EVENT_UNPUBLISHED,
    UPDATE_SCHED_FILTER,
    RECEIVE_TRACK_ALLOWED_LOC,
    RECEIVE_PROPOSED_SCHED_LOCK,
    LOCK_PROPOSED_SCHED
} from "../actions/schedule-actions";
import {RECEIVE_SUMMIT} from "../actions/base-actions";
import store from "../store";


const DEFAULT_STATE = {
    events: [],
    selectedLocation: null,
    selectedDay: null,
    selectedTrackId: null,
    selectedSlotSize: 10,
    summitTZ: 'GMT',
    trackAllowedLoc: null,
    isLocked: false,
};

const scheduleReducer = (state = DEFAULT_STATE, action) => {
    const { type, payload } = action;

    switch(type){
        case LOGOUT_USER:
            return DEFAULT_STATE;
        case RECEIVE_SUMMIT: {
            let entity = {...payload.response};
            return {...DEFAULT_STATE, summitTZ: entity.time_zone_id};
        }
        case RECEIVE_TRACK_ALLOWED_LOC: {
            const trackAllowedLoc = payload?.response?.data?.length > 0 ? payload.response.data : null;
            return {...state, trackAllowedLoc};
        }
        case REQUEST_PROPOSED_SCHED: {
            const {page} = payload;
            const events = page === 1 ? [] : state.events;
            return {...state, events, isLocked: false};
        }
        case RECEIVE_PROPOSED_SCHED: {
            const {tracksForUser} = store.getState().baseState;
            const schedEvents = payload.response.data;
            const events = schedEvents.map(ev => parseEvent(ev, state.summitTZ, tracksForUser));
            return {...state, events: [...state.events, ...events]};
        }
        case RECEIVE_SHOW_ALWAYS_EVENTS: {
            const {data} = payload.response;
            const {events} = state;
            const showAlwaysEvents = data.map(ev => ({
                ...ev,
                day: epochToMomentTimeZone(ev.start_date, state.summitTZ).format('YYYY-MM-DD'),
                static: true
            }));
            return {...state, events: [...events, ...showAlwaysEvents]};
        }
        case RECEIVE_PROPOSED_SCHED_LOCK: {
            const locks = payload.response.data;
            const isLocked = !!locks.find(lock => lock.track_id === state.selectedTrackId);
            // if locked, mark all events as static
            const events = state.events.map(ev => {
                return isLocked ? {...ev, static: true} : ev;
            })
            
            return {...state, isLocked, events};
        }
        case UPDATE_SCHED_FILTER: {
            const newState = {...payload};
            if (newState.hasOwnProperty('selectedTrackId')) {
                newState.isLocked = false;
            }
            return {...state, ...newState};
        }
        case SCHED_EVENT_PUBLISHED: {
            const schedEvent = payload.response;
            const events = state.events.filter(ev => ev.id !== schedEvent.summit_event.id);

            return {...state, events: [...events, parseEvent(schedEvent, state.summitTZ)]};
        }
        case SCHED_EVENT_UNPUBLISHED: {
            const {eventId} = payload;
            const events = state.events.filter(ev => ev.id !== eventId);
            
            return {...state, events};
        }
        case LOCK_PROPOSED_SCHED: {
            const events = state.events.map(ev => ({...ev, static: true}))
            return {...state, events, isLocked: true};
        }
        case VALIDATE: {
            // reset events
            const events = state.events.map(ev => ({...ev, last_edited: ev.last_edited + 1}));
            return {...state, errors: payload.errors, events };
        }
        default:
            return state;
    }
};

const parseEvent = (ev, summitTZ, tracksForUser= null) => {
    const {summit_event, ...rest} = ev;
    const day = epochToMomentTimeZone(ev.start_date, summitTZ).format('YYYY-MM-DD');
    const canEdit = tracksForUser ? tracksForUser.find(t => t.id === summit_event.track?.id) : true;
    
    return ({
        ...rest,
        id: summit_event.id,
        title: summit_event.title,
        is_published: true,
        day,
        static: !canEdit,
        speakers: summit_event.speakers,
        moderator: summit_event.moderator,
        track: summit_event.track,
        tags: summit_event.tags,
    });
}
export default scheduleReducer
