import React from 'react';
import {Selected, Maybe, Pass, Clear} from '../../Icons';
import {ButtonGroup, Button, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

export default class SelectionButtons extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		document.addEventListener('keyup', this.handleKey);
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.handleKey);
	}

	handleKey = ev => {
		const {presentation} = this.props;
		const {tagName} = ev.target;
		const canAdd = !presentation.selected && presentation.remaining_selections > 0;
		const canClear = !!presentation.selection_status;

		if(tagName === 'TEXTAREA' || tagName === 'INPUT') return;

		let key;
		switch (ev.keyCode) {
			case 89:
				key = 'selected';
				if (!canAdd) return;
				break;
			case 78:
				key = 'pass';
				if (presentation.selection_status === 'pass') return;
				break;
			case 73: 
				key = 'maybe';
				if (presentation.selection_status === 'maybe') return;
				break;
            case 81:
				if (!canClear) return;
                this.clearSelection();
                return;
		}

		key && this.setSelection(key);
	};

	setSelection = key => {
        const {presentation} = this.props;
		this.props.onSelect(presentation.id, key);
	};

	clearSelection = () => {
		const {presentation} = this.props;
		this.props.onClear(presentation.id, presentation.selection_status);
	};

	render() {
		const {presentation} = this.props;
		const canAdd = !presentation.selected && presentation.remaining_selections > 0;
		const canClear = !!presentation.selection_status;

		return (
			<ButtonGroup>
				<ToggleButtonGroup value={presentation.selection_status} onChange={this.setSelection} name="selection" type="radio">
					<ToggleButton value='selected' disabled={!canAdd} className="success" ><Selected /> Yes</ToggleButton>
					<ToggleButton value='maybe' className="warning"><Maybe /> Interested</ToggleButton>
					<ToggleButton value='pass' className="danger"><Pass /> No thanks</ToggleButton>
				</ToggleButtonGroup>
				<Button disabled={!canClear} onClick={this.clearSelection} className="danger"><Clear /> Clear</Button>
			</ButtonGroup>
		);		
	}
};
