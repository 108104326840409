/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
import React from 'react';
import {connect} from 'react-redux';
import {getBackURL} from "openstack-uicore-foundation/lib/utils/methods";
import {doLoginBasicLogin} from 'openstack-uicore-foundation/lib/security/methods';

import styles from '../styles/login-page.module.scss';


const LoginPage = () => {

  const onClickLogin = () => {
    doLoginBasicLogin(getBackURL());
  };

  return (
    <div className={styles.wrapper}>
      <h1>Please log in to continue</h1>

      <div className={styles.login}>
        <button className="btn btn-primary btn-lg" onClick={onClickLogin}>
          Log in
        </button>
      </div>
    </div>
  );
}


const mapStateToProps = ({loggedUserState, baseState}) => ({
  isLoggedUser: loggedUserState.isLoggedUser,
  loading: baseState.loading,
  summit: baseState.summit
});

export default connect(mapStateToProps, null)(LoginPage);


